const workshop = {
  provider: {
    name: ''
  },
  session: {
    name: ''
  },
  attendance: {
    online: false,
    inPerson: false
  },
  primaryIndustryId: null,
  description: '',
  commitmentMinutes: ''
};

export default workshop;
