import React, { useState } from 'react';
import { getContentfulData } from '@paddl/utils-js';
import { string } from 'prop-types';

export const withContenfulClient = (props) => (WrappedComponent) => {
  const WithContenfulClient = (otherProps) => {
    const [response, setResponse] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const fetch = async (params = {}) => {
      setIsLoading(true);

      try {
        const res = await getContentfulData(params);

        setResponse(res);
        setIsSuccess(true);
      } catch (err) {
        setResponse(err);
        setIsSuccess(false);
      }

      setIsLoading(false);
    };

    const { propNameWrapper = 'contentfulClient' } = props;

    // Pack up props under a new object name
    const newProps = {
      [propNameWrapper]: {
        isLoading,
        isSuccess,
        response,
        fetch
      },
      ...otherProps
    };

    // Clean up props that are no longer used
    delete newProps.propNameWrapper;

    // return same component but with new props
    return <WrappedComponent {...newProps} />;
  };

  WithContenfulClient.propTypes = {
    propNameWrapper: string
  };

  WithContenfulClient.defaultProps = {
    propNameWrapper: null
  };

  return WithContenfulClient;
};
