import React, { useContext } from 'react';
import { Artworks } from '@paddl/storybook';
import {
  getActivityProgressTypeByVariantId,
  getActivityIsProgressDisabledByVariantId
} from '@paddl/utils-js';
import { EditWrapper } from './NewActivityName.styles';
import { FormHeader } from '../../../components';
import { EmptySection } from './EmptySection';
import { ContinueButton } from './ContinueButton';
import { ActivityFormContext } from './ActivityFormContext';
import { CancelButton } from './CancelButton';
import {
  ProgressEdit,
  progressFormSchema
} from '../../Activity/ProgressSection';
import { getProgressStartLabel } from '../../Activity/ProgressSection/progressUtils';

const { progress: Progress } = Artworks;

export const ProgressSection = () => {
  const {
    isDirty, edit, variantId, setEdit, data
  } = useContext(ActivityFormContext);
  const progressClassName = edit === 'progress' ? 'editable' : 'viewable';

  const renderContinueButton = (updatedData, isDisabled) => (<ContinueButton updatedData={updatedData} isDisabled={isDisabled} type="progress" />);
  const renderCancelButton = () => <CancelButton />;
  const progressType = getActivityProgressTypeByVariantId(variantId);
  const progressStartDateLabel = getProgressStartLabel(variantId, progressType);

  const isProgressDisabled = getActivityIsProgressDisabledByVariantId(variantId);

  return (
    <section
      className={
      isDirty.progress
        ? progressClassName
        : `${progressClassName} empty-section-wrapper`
    }
    >
    {edit === 'progress' ? (
      <EditWrapper>
        <FormHeader
          icon={<Progress />}
          headline="Progress"
          subTitle="Enter your Start &amp; Finish dates for this Activity..."
        />
        <ProgressEdit
          data={{ ...data }}
          startDateLabel={progressStartDateLabel}
          continueButton={renderContinueButton}
          cancelButton={renderCancelButton}
          formSchema={progressFormSchema}
          progressType={progressType}
          isProgressDisabled={isProgressDisabled}
        />
      </EditWrapper>
    ) : (
      <EmptySection
        icon={<Progress />}
        headline="Progress"
        subTitle="Enter your Start &amp; Finish dates for this Activity..."
        setEdit={() => setEdit('progress')}
        isDirty={!!isDirty.progress}
      />
    )}
    </section>
  );
};
