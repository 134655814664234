/* eslint-disable import/extensions */
import { number } from 'prop-types';
import { getActivityTitleByVariantId, getCapitalizedComponentName } from '@paddl/utils-js';
import * as readComponents from './read';
import * as editComponents from './edit';
import * as formSchemas from './schemas';

// Bump this to pick up storybook changes?
// Bumped: Thur Nov 9 2022 17:07:40 GMT+1000 (Australian Eastern Standard Time)
export const detailsSectionFactory = (activityId) => {
  if (!activityId) throw new Error('activityId argument cannot be undefined');

  const componentName = getActivityTitleByVariantId(activityId);
  const capitalizedName = getCapitalizedComponentName(componentName);

  if (!readComponents[capitalizedName]) {
    throw new Error(`The "${capitalizedName}.jsx" file cannot be found inside the DetailsSection/read/ folder`);
  } if (!editComponents[capitalizedName]) {
    throw new Error(`The "${capitalizedName}.jsx" file cannot be found inside the DetailsSection/edit/ folder`);
  } if (!formSchemas[capitalizedName]) {
    throw new Error(`The "${capitalizedName}.js" file cannot be found inside the DetailsSection/schemas/ folder`);
  }

  return {
    DetailsRead: readComponents[capitalizedName],
    DetailsEdit: editComponents[capitalizedName],
    detailsFormSchema: formSchemas[capitalizedName]
  };
};

detailsSectionFactory.propTypes = {
  activityId: number.isRequired
};
