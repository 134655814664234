import {
  Button,
  InnerContentWrapper,
  MainContentWrapper,
  PageColumn,
  badgesMetadata
} from '@paddl/storybook';
import {
  getActivityTitleByVariantId
} from '@paddl/utils-js';
import {
  Metadata
} from '@paddl/utils-react';
import React, { useContext } from 'react';

import {
  func, shape
} from 'prop-types';
import { ActivityWrapper } from '../../Activity/Activity.styles';
import { ActivityFormContext } from './ActivityFormContext';
import { DetailsSection } from './DetailsSection';
import { MediaSection } from './MediaSection';
import {
  SubmitButtonWrapper
} from './NewActivityName.styles';
import { ProgressSection } from './ProgressSection';
import { SkillsSection } from './SkillsSection';

export const NewActivityNamePageContent = ({
  handleSubmitData, postSkillsSuggestionResponse, updateMediaCallback
}) => {
  const { variantId, sectionCompletedCount } = useContext(ActivityFormContext);
  const activityTitle = getActivityTitleByVariantId(variantId);
  const activityName = badgesMetadata[activityTitle]?.name;

  return (
    <MainContentWrapper withGhostNav>
    <Metadata title={`Create ${activityName} Activity | Paddl`} />
    <PageColumn>
    <MediaSection updateMediaCallback={updateMediaCallback} />
    <InnerContentWrapper size="small">
               <ActivityWrapper>
                   <DetailsSection />
                   <ProgressSection />
                   <SkillsSection postSkillsSuggestionResponse={postSkillsSuggestionResponse} />
                   <SubmitButtonWrapper data-test-id="submit-button">
                       <Button
                         disabled={sectionCompletedCount !== 3}
                         onClick={handleSubmitData}
                         variant="contained"
                         size="large"
                       >
                          Submit Activity
                       </Button>

                   </SubmitButtonWrapper>
               </ActivityWrapper>
    </InnerContentWrapper>
    </PageColumn>
    </MainContentWrapper>);
};

NewActivityNamePageContent.propTypes = {
  handleSubmitData: func.isRequired,
  postSkillsSuggestionResponse: shape({}).isRequired,
  updateMediaCallback: func.isRequired
};
