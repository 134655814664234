import React, { useState, useEffect } from 'react';
import {
  number,
  shape,
  string,
  bool,
  func,
  oneOfType
} from 'prop-types';
import {   initGoogleLocationApi, fetchLocationSuggestions, primaryIndustryOptionList } from '@paddl/utils-js';
import Grid from '@mui/material/Grid';
import {
  TextField,
  Dropdown,
  Checkbox,
  AutocompleteSelect,
  useWidth
} from '@paddl/storybook';
import {
  updateValueByPathLookup,
  handleSuggestionList,
  sanitizeSelectedValue
} from '../../utils';

export const SideHustle = ({
  cancelButton,
  continueButton,
  formSchema,
  data,
  data: {
    details: {
      role = {},
      employer = {},
      workingLocation = {},
      primaryIndustryId,
      hoursPerWeek,
      description,
      motivation
    }
  }
}) => {
  const width = useWidth();
  const [newData, setNewData] = useState(data);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [optionsEmployer, setOptionsEmployer] = useState([]);

  const descriptionLength = newData.details.description.length;

  const handleChange = (stringPath) => {
    const updatedData = updateValueByPathLookup(newData, stringPath);

    if (!isDirty) setIsDirty(true);
    if (JSON.stringify(newData) !== JSON.stringify(updatedData)) setNewData(updatedData);
  };

  const handleLocations = (address = '') => {
    fetchLocationSuggestions(address, (locations) => {
      if (currentAddress !== address) {
        setCurrentAddress(address);
        setLocationSuggestions(locations);
      }
    });
  };

  useEffect(() => {
    if (isDirty && formSchema.isValidSync(newData)) setIsDisabled(false);
    if (isDirty && !formSchema.isValidSync(newData)) setIsDisabled(true);
    if (typeof window.google === 'undefined') initGoogleLocationApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, formSchema]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Role / Title"
            data-test-id="role"
            placeholder="e.g  Delivery Driver"
            onChange={(item) => handleChange([
              { path: 'details.role.name', value: item },
              { path: 'details.role.id', value: null }
            ])}
            defaultValue={role.name}
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Employer Name"
            data-test-id="employer-name"
            placeholder="e.g Uber Eats"
            defaultValue={employer.name}
            options={optionsEmployer}
            action={(item) => handleChange([
              {
                path: 'details.employer',
                value: {
                  name: (item && (item.title.includes('Use') ? sanitizeSelectedValue(item) : item.title)) || null,
                  id: (item && item.value) || null,
                  profileId: null,
                  icon: null,
                  location: {}
                }
              }
            ])}
            resetFieldAfterAction={false}
            getInputValue={async (value) => setOptionsEmployer(await handleSuggestionList(value, 'employment/employers'))}
            allowCreateNewOption
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label={width === 'md' ? 'Commitment hrs/wk' : 'Commitment (Hours per Week)'}
            data-test-id="commitment"
            placeholder="40"
            onChange={(item) => handleChange([{ path: 'details.hoursPerWeek', value: item }])}
            defaultValue={hoursPerWeek ? hoursPerWeek.toString() : ''}
            maxLength={2}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={primaryIndustryId}
            label="Primary Industry"
            data-test-id="primary-industry"
            placeholder="Accounting"
            onChange={(item) => handleChange([
              { path: 'details.primaryIndustryId', value: item }
            ])}
            items={primaryIndustryOptionList()}
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Working Location *"
            action={(item) => {
              const location = item || '';
              handleChange([{
                path: 'details.workingLocation',
                value: {
                  placeId: location.value,
                  originalText: location.title,
                  shortText: location.shortText,
                  lat: location.lat,
                  lng: location.lng
                }
              }]);
            }}
            defaultValue={workingLocation.originalText}
            options={locationSuggestions}
            getInputValue={(value) => handleLocations(value)}
          />
        </Grid>
        <Grid item xs={12} className="checkbox-label">Motivation *</Grid>
        <Grid item xs={3}>
          <Checkbox
            defaultChecked={motivation.passion}
            label="Passion"
            data-test-id="passion"
            value={0}
            onChange={(isChecked) => handleChange([
              { path: 'details.motivation.passion', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={3}>
          <Checkbox
            defaultChecked={motivation.income}
            label="Income"
            data-test-id="income"
            value={0}
            onChange={(isChecked) => handleChange([
              { path: 'details.motivation.income', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={3}>
          <Checkbox
            defaultChecked={motivation.learning}
            label="Learning"
            data-test-id="learning"
            value={0}
            onChange={(isChecked) => handleChange([
              { path: 'details.motivation.learning', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={3}>
          <Checkbox
            defaultChecked={motivation.fun}
            label="Fun"
            data-test-id="fun"
            value={0}
            onChange={(isChecked) => handleChange([
              { path: 'details.motivation.fun', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description (Optional)"
            data-test-id="description"
            placeholder="Share more about your day-to-day role and key responsibilities..."
            maxLength={1500}
            onChange={(item) => handleChange([
              { path: 'details.description', value: item }
            ])}
            defaultValue={description}
            isOutlined
            multiline
            helperText={`Max 1500${descriptionLength > 0 ? `/${descriptionLength}` : ''} Characters`}
          />
        </Grid>
      </Grid>
      <div className="footer">
        {cancelButton ? cancelButton() : <div />}
        {continueButton(newData, isDisabled)}
      </div>
    </>
  );
};

SideHustle.propTypes = {
  cancelButton: func.isRequired,
  continueButton: func.isRequired,
  formSchema: shape({}).isRequired,
  data: shape({
    details: shape({
      role: shape({
        id: number,
        name: string
      }),
      employer: shape({
        id: number,
        name: string
      }),
      workingLocation: shape({
        originalText: string
      }),
      primaryIndustryId: oneOfType([number, string]),
      hoursPerWeek: oneOfType([number, string]),
      description: string,
      motivation: {
        passion: bool,
        income: bool,
        learning: bool,
        fun: bool
      }.isRequired
    }).isRequired
  }).isRequired
};

export default SideHustle;
