import React, { useState, useEffect, useRef } from 'react';
import { shape, func } from 'prop-types';
import { compose } from 'recompose';
import { AutocompleteSelect, ActivityMediaWrapper } from '@paddl/storybook';
import { withContenfulClient } from '../../../../hoc/withContenfulClient';
import { updateValueByPathLookup } from '../../utils';

export const PaddlGamesComp = ({
  contentfulClient: {
    fetch,
    isSuccess,
    response
  },
  data,
  updateDataCallback
}) => {
  const [contentfulResponse, setContentfulResponse] = useState([]);
  const [options, setOptions] = useState([]);

  const handleChange = (stringPath) => {
    const updatedData = updateValueByPathLookup(data, stringPath);

    updateDataCallback(updatedData);
  };

  const contentfulResponseTransformer = (contentfulRes = []) => {
    const optionList = contentfulRes.map(({
      fields: contentfulFields,
      fields: { name: title, duration: { startDate: { year, month, day } } },
      sys: { id: eventId }
    }) => ({
      title,
      date: new Date(year, month, day),
      eventId,
      contentfulFields
    }));

    const orderedOptionList = optionList.sort((a, b) =>
      new Date(b.date) - new Date(a.date));

    setOptions(orderedOptionList);
  };

  const hasUpdated = useRef(false);

  useEffect(() => {
    if (!hasUpdated.current) {
      fetch({ content_type: 'paddlGames' });
      hasUpdated.current = true;
    }

    if (hasUpdated.current && response && isSuccess && Object.keys(contentfulResponse).length === 0) {
      contentfulResponseTransformer(response.items);
      setContentfulResponse(response.items);
    }
  }, [fetch, isSuccess, response, contentfulResponse]);

  return (
    <ActivityMediaWrapper category="competition">
      <AutocompleteSelect
        label="Select a Paddl Games Event"
        options={options}
        isRequired
        action={(item) => {
          const {
            duration,
            name: title,
            hostCompany,
            location,
            challengeFocus,
            eventId
          } = item.contentfulFields;

          handleChange([
            { path: 'details.eventId', value: eventId },
            { path: 'duration', value: duration },
            {
              path: 'contentfulFields',
              value: {
                title,
                hostCompany,
                location,
                challengeFocus
              }
            }
          ]);
        }}
      />
    </ActivityMediaWrapper>
  );
};

PaddlGamesComp.propTypes = {
  contentfulClient: shape({
    fetch: func.isrequired,
    response: shape({}).isRequired
  }).isRequired,
  data: shape({}).isRequired,
  updateDataCallback: func.isRequired
};

const PaddlGames = compose(
  withContenfulClient({ propNameWrapper: 'contentfulClient' })
)(PaddlGamesComp);

export default PaddlGames;
