import React, { useState, useEffect } from 'react';
import {
  number,
  shape,
  string,
  oneOf,
  func,
  oneOfType
} from 'prop-types';
import {
  initGoogleLocationApi,
  fetchLocationSuggestions,
  activityDurationUnitOptionList,
  competitionPlaceUnionTypes,
  competitionPlaceOptionList,
  getActivityDurationNumberOptionList
} from '@paddl/utils-js';
import Grid from '@mui/material/Grid';
import {
  TextField,
  Dropdown,
  AutocompleteSelect
} from '@paddl/storybook';
import {
  updateValueByPathLookup,
  handleSuggestionList,
  sanitizeSelectedValue
} from '../../utils';

const Hackathon = ({
  cancelButton,
  continueButton,
  formSchema,
  data,
  data: {
    details: {
      event = {},
      organiser = {},
      eventDuration = {},
      eventLocation = {},
      competitionPlace,
      challengeDescription
    }
  }
}) => {
  const [newData, setNewData] = useState(data);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [optionsEvent, setOptionsEvent] = useState([]);
  const [optionsOrganiser, setOptionsOrganiser] = useState([]);

  const descriptionLength = newData.details.challengeDescription.length;

  const handleChange = (stringPath) => {
    const updatedData = updateValueByPathLookup(newData, stringPath);

    if (!isDirty) setIsDirty(true);
    if (JSON.stringify(newData) !== JSON.stringify(updatedData)) setNewData(updatedData);
  };

  const handleLocations = (address = '') => {
    fetchLocationSuggestions(address, (locations) => {
      if (currentAddress !== address) {
        setCurrentAddress(address);
        setLocationSuggestions(locations);
      }
    });
  };

  useEffect(() => {
    try {
      formSchema.validate(newData);
    } catch (error) {
      console.error(error);
    }

    if (isDirty && formSchema.isValidSync(newData)) setIsDisabled(false);
    if (isDirty && !formSchema.isValidSync(newData)) setIsDisabled(true);
    if (typeof window.google === 'undefined') initGoogleLocationApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, formSchema]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Event Name"
            data-test-id="event-name"
            placeholder="e.g Business Growth Hack"
            defaultValue={event.name}
            options={optionsEvent}
            action={(item) => handleChange([
              { path: 'details.event.name', value: (item && sanitizeSelectedValue(item)) || null },
              { path: 'details.event.id', value: (item && item.value) || null }
            ])}
            resetFieldAfterAction={false}
            getInputValue={async (value) => {
              setOptionsEvent(await handleSuggestionList(value, 'competition/hackathon-events'));
            }}
            allowCreateNewOption
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Organiser"
            data-test-id="organiser-name"
            placeholder="e.g AngelHack"
            defaultValue={organiser.name}
            options={optionsOrganiser}
            action={(item) => handleChange([
              {
                path: 'details.organiser',
                value: {
                  name: (item && (item.title.includes('Use') ? sanitizeSelectedValue(item) : item.title)) || null,
                  id: (item && item.value) || null,
                  profileId: null,
                  icon: null,
                  location: {}
                }
              }
            ])}
            resetFieldAfterAction={false}
            getInputValue={async (value) => setOptionsOrganiser(await handleSuggestionList(value, 'competition/hackathon/organisers'))}
            allowCreateNewOption
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Event Location *"
            placeholder="e.g. Melbourne, Vic AUS"
            action={(item) => {
              const location = item || '';
              handleChange([{
                path: 'details.eventLocation',
                value: {
                  placeId: location.value,
                  originalText: location.title,
                  shortText: location.shortText,
                  lat: location.lat,
                  lng: location.lng
                }
              }]);
            }}
            defaultValue={eventLocation.originalText}
            options={locationSuggestions}
            getInputValue={(value) => handleLocations(value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Dropdown
            defaultValue={eventDuration.value}
            label="Duration"
            data-test-id="duration"
            placeholder="Duration"
            onChange={(item) => handleChange([
              { path: 'details.eventDuration.value', value: item }
            ])}
            items={getActivityDurationNumberOptionList()}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Dropdown
            defaultValue={eventDuration.unit}
            label="Unit"
            data-test-id="unit"
            placeholder="Duration"
            onChange={(item) => handleChange([
              { path: 'details.eventDuration.unit', value: item }
            ])}
            items={activityDurationUnitOptionList}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={competitionPlace || ''}
            label="Competition Place (Optional)"
            data-test-id="competition-place"
            placeholder="Competition Place"
            onChange={(item) => handleChange([
              { path: 'details.competitionPlace', value: item }
            ])}
            items={competitionPlaceOptionList}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Challenge Description (Optional)"
            data-test-id="challenge-description"
            placeholder="Describe the challenge you were given and how you responded..."
            maxLength={1500}
            onChange={(item) => handleChange([
              { path: 'details.challengeDescription', value: item }
            ])}
            defaultValue={challengeDescription}
            multiline
            isOutlined
            helperText={`Max 1500${descriptionLength > 0 ? `/${descriptionLength}` : ''} Characters`}
          />
        </Grid>
      </Grid>
      <div className="footer">
        {cancelButton ? cancelButton() : <div />}
        {continueButton(newData, isDisabled)}
      </div>
    </>
  );
};

Hackathon.propTypes = {
  cancelButton: func.isRequired,
  continueButton: func.isRequired,
  formSchema: shape({}).isRequired,
  data: shape({
    details: shape({
      event: shape({
        id: number,
        name: string
      }),
      organiser: shape({
        id: number,
        name: string
      }),
      eventDuration: shape({
        value: oneOfType([number, string]),
        unit: string
      }),
      eventLocation: shape({
        originalText: string,
        shortText: string
      }),
      competitionPlace: oneOf([...competitionPlaceUnionTypes, '', null]),
      challengeDescription: string
    }).isRequired
  }).isRequired
};

export default Hackathon;
