import * as Yup from 'yup';

const meetup = Yup.object().shape({
  details: Yup.object().shape({
    name: Yup.string().required(),
    organiser: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    meetupLocation: Yup.object().shape({
      originalText: Yup.string().required(),
      shortText: Yup.string()
    }).defined(),
    commitmentHours: Yup.number().required()
      .test('range', 'Total should range between 1 and 99', (value) => value >= 1 && value <= 99),
    typeId: Yup.number().nullable(),
    attendeesId: Yup.number().nullable(),
    description: Yup.string().nullable(),
    isVirtual: Yup.boolean().nullable(),
    isHost: Yup.boolean().nullable()
  })
}).defined();

export default meetup;
