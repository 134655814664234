import React from 'react';
import {
  string, node, func, bool
} from 'prop-types';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import ButtonBase from '@mui/material/ButtonBase';
import {
  H4, LabelText, Colors, Column, Row
} from '@paddl/storybook';
import styled from '@emotion/styled';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';

const CompletedIconWrapper = styled.div`
  border-radius: 100%;
  background-color: ${Colors.sec_green};
  height: 48px;
  width:48px;

  svg {
    color: white;
    height: 24px;
    width: 24px;
    padding: 12px;
  }
`;

export const CompletedIcon = () => <CompletedIconWrapper><CheckSharpIcon /></CompletedIconWrapper>;

const IconWrapper = styled.div`
  width: 48px;
  height: 48px; 

  svg {
    width: 48px;
    height: 48px;
  }
`;

const ClickableArea = styled(ButtonBase)`
  width: 100%;
  padding: 32px 60px;
  border-radius: 4px;

  &:hover {
    background-color:  ${Colors.grey_magnolia};
  }
`;

export const EmptySection = ({
  icon,
  headline,
  subTitle,
  setEdit,
  isDirty
}) =>
  <ClickableArea onClick={() => setEdit()}>
    <Row style={{ width: '100%' }}>
      {isDirty ? <CompletedIcon /> : <IconWrapper>{icon}</IconWrapper>}
      <Column style={{ flex: 1, gap: '4px', textAlign: 'start' }}>
        <H4>{headline}</H4>
        <LabelText>{subTitle}</LabelText>
      </Column>
      <EditSharpIcon style={{ color: Colors.new_grey_light }} />
    </Row>
  </ClickableArea>;

EmptySection.defaultProps = {
  isDirty: false
};

EmptySection.propTypes = {
  icon: node.isRequired,
  headline: string.isRequired,
  subTitle: string.isRequired,
  setEdit: func.isRequired,
  isDirty: bool
};
