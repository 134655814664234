import * as Yup from 'yup';

export const progressFormSchema = Yup.object().shape({
  duration: Yup.object().shape({
    startDate: Yup.object().shape({
      day: Yup.number().nullable(),
      month: Yup.number().nullable(),
      year: Yup.number().nullable()
    }).defined(),
    endDate: Yup.object().shape({
      day: Yup.number().nullable(),
      month: Yup.number().nullable(),
      year: Yup.number().nullable()
    }).defined(),
    timeStamp: Yup.object().shape({
      day: Yup.number().nullable(),
      month: Yup.number().nullable(),
      year: Yup.number().nullable()
    }).defined(),
    isCurrent: Yup.boolean()
  }),
  percentageComplete: Yup.number().nullable()
}).defined();
