import React, { useContext } from 'react';
import { Button } from '@paddl/storybook';
import { bool, oneOf, shape } from 'prop-types';
import { ActivityFormContext } from './ActivityFormContext';

export const ContinueButton = ({
  isDisabled, type, updatedData
}) => {
  const {
    setData, setEdit, setIsDirty, isDirty
  } = useContext(ActivityFormContext);

  return (
        <Button
          onClick={() => {
            setData(updatedData);
            setEdit(null);

            if (type) {
              setIsDirty({
                ...isDirty,
                [type]: true
              });
            }
          }}
          variant="contained"
          disabled={isDisabled}
        >
      Continue
        </Button>
  );
};
ContinueButton.defaultProps = {
  isDisabled: false
};

ContinueButton.propTypes = {
  isDisabled: bool,
  type: oneOf(['details', 'progress', 'skills']).isRequired,
  updatedData: shape({}).isRequired
};
