import * as Yup from 'yup';

const hackathon = Yup.object().shape({
  details: Yup.object().shape({
    event: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    organiser: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    eventDuration: Yup.object().shape({
      unit: Yup.string().required(),
      value: Yup.number().required()
    }).defined(),
    eventLocation: Yup.object().shape({
      originalText: Yup.string().required(),
      shortText: Yup.string()
    }).defined(),
    competitionPlace: Yup.number().nullable(),
    challengeDescription: Yup.string()
  })
}).defined();

export default hackathon;
