import * as Yup from 'yup';
import { atLeastOneOfBoolean } from '../../addMethods.yup';

atLeastOneOfBoolean();

const partTime = Yup.object().shape({
  details: Yup.object().shape({
    role: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    employer: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    workingLocation: Yup.object().shape({
      originalText: Yup.string().required(),
      shortText: Yup.string()
    }).defined(),
    primaryIndustryId: Yup.number().required(),
    hoursPerWeek: Yup.number().required()
      .test('range', 'Total should range between 1 and 99', (value) => value >= 1 && value <= 99),
    level: Yup.string().required(),
    responsibility: Yup.string().required(),
    description: Yup.string().nullable(),
    attendanceInHouse: Yup.boolean(),
    attendanceRemote: Yup.boolean()
  }).atLeastOneOfBoolean([
    'attendanceInHouse',
    'attendanceRemote'
  ])
}).defined();

export default partTime;
