import React, { useContext } from 'react';
import { Button } from '@paddl/storybook';
import { ActivityFormContext } from './ActivityFormContext';

export const CancelButton = () => {
  const { setEdit } = useContext(ActivityFormContext);

  return (
        <Button onClick={() => setEdit(null)} variant="contained" color="white">
        Cancel
        </Button>
  );
};
