const volunteer = {
  role: {
    name: ''
  },
  organisation: {
    name: ''
  },
  volunteerLocation: {
    originalText: '',
    shortText: ''
  },
  primaryIndustryId: '',
  hoursPerWeek: '',
  description: '',
  purpose: {
    charity: false,
    support: false,
    experience: false,
    community: false
  }
};

export default volunteer;
