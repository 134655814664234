const course = {
  trainer: {
    name: ''
  },
  course: {
    name: ''
  },
  courseDuration: {
    unit: '',
    value: ''
  },
  commitment: '',
  attendanceOnline: false,
  attendanceOnCampus: false
};

export default course;
