import * as Yup from 'yup';

const workshop = Yup.object().shape({
  details: Yup.object().shape({
    session: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    provider: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    attendance: Yup.object().shape({
      online: Yup.boolean().required(),
      inPerson: Yup.boolean().required()
    }),
    primaryIndustryId: Yup.number().nullable(),
    commitmentMinutes: Yup.number().required()
      .test('range', 'Total should range between 1 and 999999', (value) => value >= 1 && value <= 999999),
    description: Yup.string().nullable()
  })
}).defined();

export default workshop;
