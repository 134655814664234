import * as Yup from 'yup';

/* eslint-disable no-template-curly-in-string, func-names */
Yup.addMethod(Yup.array, 'noneEmptyArray', function () {
  return this.test(
    'noneEmpty',
    'At least one skill must be selected',
    (arr) => Boolean(arr.length > 0)
  );
});

export const skillsFormSchema = Yup.object().shape({
  skillset: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().nullable(),
      name: Yup.string().required()
    })
  ).noneEmptyArray()
}).defined();
