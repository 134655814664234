import React, { useState, useEffect } from 'react';
import {
  number,
  shape,
  string,
  oneOf,
  func,
  oneOfType
} from 'prop-types';
import {
  commitmentOptionList,
  courseOptionList,
  commitmentUnionTypes
} from '@paddl/utils-js';
import Grid from '@mui/material/Grid';
import { AutocompleteSelect, TextField, Dropdown } from '@paddl/storybook';
import { updateValueByPathLookup, handleSuggestionList, sanitizeSelectedValue } from '../../utils';

const Phd = ({
  cancelButton,
  continueButton,
  formSchema,
  data,
  data: {
    details: {
      educator = {},
      campus = {},
      title = {},
      thesisTitle,
      thesisDescription,
      duration,
      commitment
    }
  }
}) => {
  const [newData, setNewData] = useState(data);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [optionsEducator, setOptionsEducator] = useState([]);
  const [optionsCampuses, setOptionsCampuses] = useState([]);

  const descriptionLength = newData.details.thesisDescription.length;

  const handleChange = (stringPath) => {
    const updatedData = updateValueByPathLookup(newData, stringPath);

    if (!isDirty) setIsDirty(true);
    if (JSON.stringify(newData) !== JSON.stringify(updatedData)) setNewData(updatedData);
  };

  useEffect(() => {
    if (isDirty && formSchema.isValidSync(newData)) setIsDisabled(false);
    if (isDirty && !formSchema.isValidSync(newData)) setIsDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, newData, formSchema]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Title (Field of Study)"
            placeholder="e.g Doctor of Philosophy"
            onChange={(item) => handleChange([
              { path: 'details.title.name', value: item },
              { path: 'details.title.id', value: null }
            ])}
            defaultValue={title.name}
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Institution"
            data-test-id="institution-name"
            placeholder="e.g Harvard University"
            defaultValue={educator.name}
            options={optionsEducator}
            action={(item) => handleChange([
              { path: 'details.educator.name', value: (item && sanitizeSelectedValue(item)) || null },
              { path: 'details.educator.id', value: (item && item.value) || null }
            ])}
            getInputValue={async (value) => setOptionsEducator(await handleSuggestionList(value, 'education/educators'))}
            resetFieldAfterAction={false}
            allowCreateNewOption
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Campus"
            data-test-id="campus"
            placeholder="e.g Cambridge"
            defaultValue={campus.name}
            options={optionsCampuses}
            action={(item) => handleChange([
              { path: 'details.campus.name', value: (item && sanitizeSelectedValue(item)) || null },
              { path: 'details.campus.id', value: (item && item.value) || null }
            ])}
            getInputValue={async (value) => setOptionsCampuses(await handleSuggestionList(value, 'education/campuses'))}
            resetFieldAfterAction={false}
            allowCreateNewOption
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Thesis Title (Optional)"
            data-test-id="thesis-title"
            placeholder="Thesis Title"
            onChange={(item) => handleChange([
              { path: 'details.thesisTitle', value: item }
            ])}
            defaultValue={thesisTitle}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Thesis Description (Optional)"
            data-test-id="thesis-description"
            placeholder="Thesis Description"
            maxLength={1500}
            onChange={(item) => handleChange([
              { path: 'details.thesisDescription', value: item }
            ])}
            defaultValue={thesisDescription}
            multiline
            isOutlined
            helperText={`Max 1500${descriptionLength > 0 ? `/${descriptionLength}` : ''} Characters`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={duration}
            data-test-id="duration"
            label="Duration"
            placeholder="Duration"
            onChange={(item) => handleChange([
              { path: 'details.duration', value: item }
            ])}
            items={courseOptionList}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={commitment}
            label="Commitment"
            data-test-id="commitment"
            placeholder="Commitment"
            onChange={(item) => handleChange([
              { path: 'details.commitment', value: item }
            ])}
            items={commitmentOptionList}
            isRequired
          />
        </Grid>
      </Grid>
      <div className="footer">
        {cancelButton()}
        {continueButton(newData, isDisabled)}
      </div>
    </>
  );
};

Phd.propTypes = {
  cancelButton: func.isRequired,
  continueButton: func.isRequired,
  formSchema: shape({}).isRequired,
  data: shape({
    details: shape({
      educator: shape({
        id: number,
        name: string
      }),
      campus: shape({
        id: number,
        name: string
      }),
      title: shape({
        id: number,
        name: string
      }),
      thesisTitle: string,
      thesisDescription: string,
      duration: oneOfType([number, string]),
      commitment: oneOf([...commitmentUnionTypes, ''])
    }).isRequired
  }).isRequired
};

export default Phd;
