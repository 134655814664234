const highSchool = {
  school: {
    name: ''
  },
  campus: {
    name: ''
  },
  location: '',
  grade: '',
  subjects: [{
    name: '',
    id: ''
  }],
  attendanceOnline: false,
  attendanceOnCampus: false
};

export default highSchool;
