import React, { useState, useEffect } from 'react';
import {
  shape,
  number,
  string,
  bool,
  func,
  oneOfType,
  oneOf
} from 'prop-types';
import {
  competitionPlaceUnionTypes,
  competitionPlaceOptionList
} from '@paddl/utils-js';
import Grid from '@mui/material/Grid';
import { TextField, Dropdown, Checkbox } from '@paddl/storybook';
import { updateValueByPathLookup } from '../../utils';

const PaddlGames = ({
  cancelButton,
  continueButton,
  formSchema,
  data,
  data: {
    contentfulFields: {
      title,
      hostCompany,
      location,
      challengeFocus
    } = {},
    details: {
      eventId,
      roleCreative,
      roleDemonstrator,
      rolePresenter,
      roleTeamLeader,
      competitionPlace
    }
  }
}) => {
  const [newData, setNewData] = useState(data);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  const handleChange = (stringPath) => {
    const updatedData = updateValueByPathLookup(newData, stringPath);

    if (!isDirty) setIsDirty(true);
    if (JSON.stringify(newData) !== JSON.stringify(updatedData)) setNewData(updatedData);
  };

  useEffect(() => {
    if (eventId !== newData.details.eventId) {
      setNewData({
        ...data,
        details: {
          ...newData.details,
          eventId
        }
      });
    }
    if (isDirty && formSchema.isValidSync(newData)) setIsDisabled(false);
    if (isDirty && !formSchema.isValidSync(newData)) setIsDisabled(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, formSchema, eventId]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Event Name"
            defaultValue={title}
            isControlled
            isRequired
            isDisabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Host Company"
            defaultValue={hostCompany && hostCompany.title}
            isControlled
            isRequired
            isDisabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Location"
            defaultValue={location}
            isControlled
            isRequired
            isDisabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={competitionPlace || ''}
            label="Competition Place"
            data-test-id="competition-place"
            placeholder="Competition Place"
            onChange={(item) => handleChange([
              { path: 'details.competitionPlace', value: item }
            ])}
            items={competitionPlaceOptionList}
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Challenge Description"
            defaultValue={challengeFocus}
            multiline
            isOutlined
            isControlled
            isRequired
            isDisabled
          />
        </Grid>
        <Grid item xs={12} className="checkbox-label">Challenge Role/s *</Grid>
        <Grid item xs={6} lg={3}>
          <Checkbox
            defaultChecked={roleTeamLeader}
            label="Team Leader"
            data-test-id="team-leader"
            value={0}
            onChange={(isChecked) => handleChange([
              { path: 'details.roleTeamLeader', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <Checkbox
            defaultChecked={rolePresenter}
            label="Presenter"
            data-test-id="presenter"
            value={1}
            onChange={(isChecked) => handleChange([
              { path: 'details.rolePresenter', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <Checkbox
            defaultChecked={roleDemonstrator}
            label="Demonstrator"
            data-test-id="demonstrator"
            value={2}
            onChange={(isChecked) => handleChange([
              { path: 'details.roleDemonstrator', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <Checkbox
            defaultChecked={roleCreative}
            label="Creative"
            data-test-id="creative"
            value={3}
            onChange={(isChecked) => handleChange([
              { path: 'details.roleCreative', value: isChecked }
            ])}
          />
        </Grid>
      </Grid>
      <div className="footer">
        {cancelButton ? cancelButton() : <div />}
        {continueButton(newData, isDisabled)}
      </div>
    </>
  );
};

PaddlGames.propTypes = {
  cancelButton: func.isRequired,
  continueButton: func.isRequired,
  formSchema: shape({}).isRequired,
  data: shape({
    details: shape({
      competitionPlace: oneOf([...competitionPlaceUnionTypes, '']),
      eventId: oneOfType([number, string]),
      roleCreative: bool,
      roleDemonstrator: bool,
      rolePresenter: bool,
      roleTeamLeader: bool
    }).isRequired
  }).isRequired
};

export default PaddlGames;
