import * as Yup from 'yup';

const phd = Yup.object().shape({
  details: Yup.object().shape({
    educator: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    campus: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    title: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    thesisTitle: Yup.string(),
    thesisDescription: Yup.string(),
    duration: Yup.number().required(),
    commitment: Yup.string().required()
  })
}).defined();

export default phd;
