import React, { useContext } from 'react';
import {
  getActivityTitleByVariantId,
  getCategoryNameByVariantId
} from '@paddl/utils-js';
import { badgesMetadata } from '@paddl/storybook';
import { func } from 'prop-types';
import { ActivityFormContext } from './ActivityFormContext';
import PaddlGames from '../../Activity/MediaSection/edit/PaddlGames';
import DefaultMedia from '../../Activity/MediaSection/edit/DefaultMedia';

export const MediaSection = ({ updateMediaCallback }) => {
  const { data, variantId, setData } = useContext(ActivityFormContext);
  const activityTitle = getActivityTitleByVariantId(variantId);
  const activityName = badgesMetadata[activityTitle]?.name;
  const categoryName = getCategoryNameByVariantId(variantId || 1);

  return variantId.toString() === '4'
    ? <PaddlGames
        data={{ ...data }}
        updateDataCallback={setData}
    />
    : <DefaultMedia
        data={{ ...data }}
        activityName={activityName}
        categoryName={categoryName}
        updateMediaCallback={updateMediaCallback}
    />;
};

MediaSection.propTypes = {
  updateMediaCallback: func.isRequired
};
