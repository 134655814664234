import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Colors, Fonts, Elevations } from '@paddl/storybook';

const {
  white,
  sec_red,
  grey_athens
} = Colors;

export const ShowMoreIconWrapper = styled.div`
  button {
    transition: background 0.25s ease;
  }
`;

export const menuWrapperGlobal = css`
  .custom-paper {
    &.MuiPaper-root {
      background-color: ${white};
      box-shadow: ${Elevations.elevation};
      margin-top: 42px;
    }
  }

  .custom-list {
    &.MuiList-root {
      &.MuiList-padding {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .custom-button {
    &.MuiMenuItem-root {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  .custom-button {
    &.MuiListItem-button {
      transition: background 0.25s ease;

      &:hover {
        background-color: ${grey_athens};
      }
    }
  }
`;

export const RemoveText = styled.div`
  ${Fonts.body};
  color: ${sec_red};
`;
