import * as Yup from 'yup';

const highSchool = Yup.object().shape({
  details: Yup.object().shape({
    school: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    campus: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    location: Yup.string().required(),
    grade: Yup.string(),
    subjects: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required()
            .test('len', 'Must be within 190 characters', (val) => val.length <= 190),
          id: Yup.number().nullable()
        })
      ).defined()
  })
}).defined();

export default highSchool;
