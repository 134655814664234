import React from 'react';
import { string, node } from 'prop-types';

import styled from '@emotion/styled';
import {
  H4, LabelText, Column, Row
} from '@paddl/storybook';

const IconWrapper = styled.div`
  width: 48px;
  height: 48px; 

  svg {
    width: 48px;
    height: 48px;
  }
`;

export const FormHeader = ({ icon, headline, subTitle }) =>
<Row style={{ width: '100%', paddingBottom: '32px' }}>
<IconWrapper>{icon}</IconWrapper>
<Column style={{ flex: 1, gap: '4px', textAlign: 'start' }}>
  <H4>{headline}</H4>
  <LabelText>{subTitle}</LabelText>
</Column>
</Row>;

FormHeader.propTypes = {
  icon: node.isRequired,
  headline: string.isRequired,
  subTitle: string.isRequired
};
