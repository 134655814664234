import React, { useState, useEffect } from 'react';
import {
  number,
  shape,
  string,
  bool,
  func,
  oneOfType
} from 'prop-types';
import {
  primaryIndustryOptionList
} from '@paddl/utils-js';
import Grid from '@mui/material/Grid';
import {
  TextField,
  Dropdown,
  Checkbox,
  useWidth
} from '@paddl/storybook';
import {
  updateValueByPathLookup
} from '../../utils';

export const Membership = ({
  cancelButton,
  continueButton,
  formSchema,
  data,
  data: {
    details: {
      role = {},
      organisation = {},
      primaryIndustryId,
      hoursPerWeek,
      description,
      responsibility
    } = {}
  } = {}
}) => {
  const width = useWidth();
  const [newData, setNewData] = useState(data);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  const descriptionLength = newData.details.description.length;

  const handleChange = (stringPath) => {
    const updatedData = updateValueByPathLookup(newData, stringPath);

    if (!isDirty) setIsDirty(true);
    if (JSON.stringify(newData) !== JSON.stringify(updatedData)) setNewData(updatedData);
  };
  useEffect(() => {
    if (isDirty && formSchema.isValidSync(newData)) setIsDisabled(false);
    if (isDirty && !formSchema.isValidSync(newData)) setIsDisabled(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, formSchema]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Name"
            placeholder="e.g StartUp Society Member"
            onChange={(item) => handleChange([
              { path: 'details.role.name', value: item },
              { path: 'details.role.id', value: null }
            ])}
            defaultValue={role.name}
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Organisation Name"
            placeholder="e.g  StartUp Society"
            onChange={(item) => handleChange([{
              path: 'details.organisation',
              value: {
                name: item,
                id: null,
                profileId: null,
                icon: null
              }
            }])}
            defaultValue={organisation.name}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label={width === 'md' ? 'Commitment hrs/wk' : 'Commitment (Hours per Week)'}
            placeholder="40"
            onChange={(item) => handleChange([{ path: 'details.hoursPerWeek', value: item }])}
            defaultValue={hoursPerWeek ? hoursPerWeek.toString() : ''}
            maxLength={2}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={primaryIndustryId}
            label="Primary Industry"
            placeholder="Accounting"
            onChange={(item) => handleChange([
              { path: 'details.primaryIndustryId', value: item }
            ])}
            items={primaryIndustryOptionList()}
            isRequired
          />
        </Grid>
        <Grid item xs={12} className="checkbox-label">Role *</Grid>
        <Grid item xs={3}>
          <Checkbox
            defaultChecked={responsibility.subscriber}
            label="Subscriber"
            value={0}
            onChange={(isChecked) => handleChange([
              { path: 'details.responsibility.subscriber', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={3}>
          <Checkbox
            defaultChecked={responsibility.member}
            label="Member"
            value={0}
            onChange={(isChecked) => handleChange([
              { path: 'details.responsibility.member', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={3}>
          <Checkbox
            defaultChecked={responsibility.organiser}
            label="Organiser"
            value={0}
            onChange={(isChecked) => handleChange([
              { path: 'details.responsibility.organiser', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={3}>
          <Checkbox
            defaultChecked={responsibility.fun}
            label="Leader"
            value={0}
            onChange={(isChecked) => handleChange([
              { path: 'details.responsibility.leader', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description (Optional)"
            placeholder="Monthly get togethers with connections..."
            maxLength={1500}
            onChange={(item) => handleChange([
              { path: 'details.description', value: item }
            ])}
            defaultValue={description}
            multiline
            isOutlined
            helperText={`Max 1500${descriptionLength > 0 ? `/${descriptionLength}` : ''} Characters`}
          />
        </Grid>
      </Grid>
      <div className="footer">
        {cancelButton ? cancelButton() : <div />}
        {continueButton(newData, isDisabled)}
      </div>
    </>
  );
};

Membership.propTypes = {
  cancelButton: func.isRequired,
  continueButton: func.isRequired,
  formSchema: shape({}).isRequired,
  data: shape({
    details: shape({
      role: shape({
        id: number,
        name: string
      }).isRequired,
      organisation: shape({
        id: number,
        name: string
      }).isRequired,
      primaryIndustryId: oneOfType([number, string]),
      hoursPerWeek: oneOfType([number, string]),
      description: string,
      responsibility: shape({
        subscriber: bool,
        member: bool,
        organiser: bool,
        leader: bool
      }).isRequired
    }).isRequired
  }).isRequired
};

export default Membership;
