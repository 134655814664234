import React from 'react';
import {
  shape,
  oneOf,
  node,
  bool
} from 'prop-types';
import {
  competitionPlaceUnionTypes,
  getCompetitionPlaceByName
} from '@paddl/utils-js';
import { compose } from 'recompose';
import {
  LogoCardWithText,
  Icons,
  DetailsWrapper,
  getRoles
} from '@paddl/storybook';
import { withContenfulClient } from '../../../../hoc/withContenfulClient';

export const PaddlGamesComp = ({
  data: {
    contentfulFields: {
      title,
      hostCompany: {
        icon: hostCompanyIcon = '',
        title: hostCompanyTitle = ''
      } = {},
      location,
      challengeFocus
    } = {},
    details: {
      roleCreative,
      roleDemonstrator,
      rolePresenter,
      roleTeamLeader,
      competitionPlace
    }
  },
  editButton
}) => {
  const Award = Icons.award;

  return (
    <DetailsWrapper>
      {editButton}
      <h2 className="headline">{title}</h2>
      <span className="location">{location}</span>
      <LogoCardWithText
        avatarUrl={`https://${hostCompanyIcon}`}
        title={hostCompanyTitle}
        subtitle="Host Company"
        isSubtitleShortened
      />
      <p>{challengeFocus}</p>
      {competitionPlace < 3
        ? <div className="place" data-test-id="competition-place">
            <span className="icon"><Award /></span>
            <span>{getCompetitionPlaceByName(competitionPlace)}</span>
          </div>
        : null}
      <div className="tags">
        {getRoles([
          roleTeamLeader,
          rolePresenter,
          roleDemonstrator,
          roleCreative
        ])}
      </div>
    </DetailsWrapper>
  );
};

PaddlGamesComp.propTypes = {
  data: shape({
    details: shape({
      competitionPlace: oneOf([...competitionPlaceUnionTypes, '']),
      roleCreative: bool,
      roleDemonstrator: bool,
      rolePresenter: bool,
      roleTeamLeader: bool
    })
  }).isRequired,
  editButton: node
};

PaddlGamesComp.defaultProps = {
  editButton: undefined
};

const PaddlGames = compose(
  withContenfulClient({ propNameWrapper: 'contentfulClient' })
)(PaddlGamesComp);

export default PaddlGames;
