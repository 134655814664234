import * as Yup from 'yup';
import { atLeastOneOfBoolean } from '../../addMethods.yup';

atLeastOneOfBoolean();

const course = Yup.object().shape({
  details: Yup.object().shape({
    trainer: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    course: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    courseDuration: Yup.object().shape({
      unit: Yup.string().required(),
      value: Yup.number().required()
    }).defined(),
    commitment: Yup.string().required(),
    attendanceOnline: Yup.boolean(),
    attendanceInPerson: Yup.boolean()
  }).atLeastOneOfBoolean([
    'attendanceOnline',
    'attendanceInPerson'
  ])
}).defined();

export default course;
