import styled from '@emotion/styled';
import {
  Colors,
  Fonts
} from '@paddl/storybook';

const {
  new_grey_light,
  grey_lighter,
  new_grey_dark,
  charcoal
} = Colors;

const {
  H3,
  labelText
} = Fonts;

export const ActivityWrapper = styled.div`
  padding-bottom: 40px;
  color: ${charcoal};

  > section {
    position: relative;
    margin-bottom: 24px;
    border-radius: 4px;
    border: 1px solid ${grey_lighter};

    .headline {
      ${H3};
      margin: 0;
    }

    &.editable {
      .checkbox-label {
        padding-bottom: 0;
        font-size: 12px;
        font-weight: normal;
        color: ${new_grey_light};
        margin-top: 14px;
      }

      .footer {
        margin-top: 64px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;

export const VerifiedPanelWrapper = styled.div`
  margin-top: 36px;
  padding: 16px;
  border-top: 1px solid ${grey_lighter};
  border-bottom: 1px solid ${grey_lighter};
`;

export const ProgressWrapper = styled.div`
  .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    ${Fonts.labelText};
    color: ${new_grey_dark};
  }

  .duration {
    ${Fonts.labelText};
    color: ${new_grey_dark};
    margin-top: 2px;
  }
`;

export const SkillsEditWrapper = styled.div`
  .MuiChip-root {
    margin: 0 16px 16px 0;
  }
`;

export const SkillsNumberTitle = styled.div`
  ${H3};
  margin: 40px 0 20px;
`;

export const SuggestionsWrapper = styled.div`
  margin-top: 20px;

  .MuiChip-root {
    transition: background 0.25s ease;

    &:hover {
      background: ${grey_lighter};
    }
  }
`;

export const SubmitButtonWrapper = styled.div`
  text-align: center;
  padding-top: 20px;
`;

export const SubHealine = styled.div`
  ${labelText};
  margin: 2px 0 0 0;
`;
