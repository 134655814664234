import {
  Colors,
  H3
} from '@paddl/storybook';
import styled from '@emotion/styled';

const {
  new_grey,
  grey_lighter
} = Colors;

export const BookmarkTitleWrapper = styled(H3)`
  text-align: center;
  margin: 0 0 36px 0;

  span {
    color: ${new_grey};
  }

  svg {
    fill: ${grey_lighter};
    margin-right: 8px;
    width: 18px;
    height: 18px;
  }
`;
