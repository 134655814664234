import styled from '@emotion/styled';
import {
  Colors,
  Fonts,
  Elevations,
  viewportWidthSmall
} from '@paddl/storybook';

const {
  white,
  new_grey_dark
} = Colors;

const {
  H2
} = Fonts;

export const ShareModalHeader = styled.div`
  text-align: center;

  h2 {
    ${H2};
    margin: 6px 0 24px;
  }

  .badge-category-icon {
    margin-top: -88px;

    svg {
      background: ${white};
      padding: 10px;
      border-radius: 50%;
      width: 92px;
      height: 92px;
      box-shadow: ${Elevations.elevation};
    }
  }
`;

export const ShareModalContentWrapper = styled.div`
  padding: 0 38px;
  @media (max-width: ${viewportWidthSmall}) {
    padding: 0 8px;
  }
`;

export const AddToLinkedinIntro = styled.p`
  text-align: center;
  width: 100%;
  ${Fonts.body};
  color: ${new_grey_dark};
`;

export const AddToLinkedinWrapper = styled.div`
  margin: 28px 0;
`;

export const ModalContentWrapper = styled.div`
  > :not(:first-child) {
    margin-top: 32px;
  }
`;
