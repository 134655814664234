import React, { useState, useEffect } from 'react';
import {
  shape,
  func,
  bool,
  string
} from 'prop-types';
import {
  ActivityMediaWrapper,
  ConfirmationModal,
  BookmarkPreview
} from '@paddl/storybook';
import { withDataSubmission } from '@paddl/utils-react';
import { compose } from 'recompose';
import { ActivityMediaForm } from '../../../../components/ActivityMediaForm';

export const DefaultMediaComp = ({
  getBookmarkPreview: {
    submit,
    isLoading,
    response,
    isSuccess
  },
  categoryName,
  activityName,
  updateMediaCallback
}) => {
  const [bookmark, setBookmark] = useState(undefined);
  const [url, setUrl] = useState(undefined);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const deleteCallback = () => setIsConfirmModalOpen(true);

  const handleCloseModal = () => setIsConfirmModalOpen(false);

  const handleGetBookmarkPreview = () =>
    submit(`${process.env.ACTIVITY_MEDIA_API_V1}/bookmark/preview`, {
      resource_url: url
    });

  const handlePressEnter = async (event) => {
    const eventCode = event?.code?.toLowerCase();

    if (eventCode === 'enter' || eventCode === 'numpadenter' || event?.keyCode === 13) {
      setBookmark(undefined);
      handleGetBookmarkPreview();
      event.preventDefault();
    }
  };

  const renderActivityMediaContent = () => (
    !isLoading && !bookmark
      ? <ActivityMediaForm
          handlePressEnter={handlePressEnter}
          activityName={activityName}
          setUrl={setUrl}
          hasError={Boolean(response?.errorMessage)}
          errorText={response?.errorMessage?.message}
      />
      : <BookmarkPreview
          image={bookmark?.metadata?.image}
          title={bookmark?.metadata?.title}
          url={bookmark?.origin_url || url}
          logo={bookmark?.metadata?.logo}
          description={bookmark?.metadata?.description}
          isLoading={isLoading}
          isDeletable
          deleteCallback={deleteCallback}
      />
  );

  useEffect(() => {
    if (isSuccess && response) setBookmark(response);
  }, [isSuccess, response]);

  useEffect(() => {
    if (isSuccess && response && url) updateMediaCallback(url, bookmark?.metadata?.description);
  }, [
    isSuccess,
    response,
    url,
    bookmark,
    updateMediaCallback
  ]);

  return (
    <>
      <ActivityMediaWrapper category={categoryName || 'education'}>
        {renderActivityMediaContent()}
      </ActivityMediaWrapper>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        handleExit={() => handleCloseModal()}
        confirmButtonCallback={() => {
          setBookmark(undefined);
          setUrl(undefined);
          updateMediaCallback(undefined, undefined);
          handleCloseModal();
        }}
        contentText="Removing a Bookmark cannot be undone."
      />
    </>
  );
};

DefaultMediaComp.propTypes = {
  updateMediaCallback: func,
  getBookmarkPreview: shape({
    submit: func.isRequired,
    isSuccess: bool.isRequired,
    isLoading: bool.isRequired,
    response: shape({}).isRequired
  }).isRequired,
  categoryName: string.isRequired,
  activityName: string.isRequired
};

DefaultMediaComp.defaultProps = {
  updateMediaCallback: () => {}
};

const DefaultMedia = compose(
  withDataSubmission({ propNameWrapper: 'getBookmarkPreview' })
)(DefaultMediaComp);

export default DefaultMedia;
