import * as Yup from 'yup';
import { atLeastOneOfBoolean } from '../../addMethods.yup';

atLeastOneOfBoolean();

const bachelorDegree = Yup.object().shape({
  details: Yup.object().shape({
    educator: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    campus: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    course: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    courseDuration: Yup.number().required(),
    commitment: Yup.string().required(),
    attendanceOnline: Yup.boolean(),
    attendanceOnCampus: Yup.boolean()
  }).atLeastOneOfBoolean([
    'attendanceOnline',
    'attendanceOnCampus'
  ])
}).defined();

export default bachelorDegree;
