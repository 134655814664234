import React, { useState, useEffect } from 'react';
import { shape, func } from 'prop-types';
import {
  getVariantIdByActivityTitle,
  getCategoryNameByVariantId,
  activityDictionary,
  getLocallyStoredDataWithKey,
  setLocalDataWithKey
} from '@paddl/utils-js';
import { ActivityChip, badgesMetadata } from '@paddl/storybook';
import { ActivityChipListWrapper } from './ActivityChipList.styles';

const defaultActivityList = ['bachelorDegree', 'casualWork', 'hackathon', 'paddlGames', 'phd'];
const localStorageNameSuffix = 'ACTIVITIES_SUGGESTION';

export const ActivityChipList = ({ history }) => {
  const [activeChips, setActivityChips] = useState([]);
  const [hasBeenDeletedOnce, setHasBeenDeletedOnce] = useState(false);

  const getRandomActivity = () => {
    const activityDictionaryLength = Object.keys(activityDictionary)?.length;

    const getNewActivity = () => {
      const randomInt = Math.floor(Math.random() * activityDictionaryLength) + 1;
      const activityTitle = activityDictionary[randomInt]?.title;
      const activityTitleIsAlreadySelected = activeChips?.includes(activityTitle);

      if (activityTitle && !activityTitleIsAlreadySelected) return activityTitle;

      return getNewActivity();
    };

    setHasBeenDeletedOnce(true);

    return getNewActivity();
  };

  const handleToogle = (item) => {
    const newChipList = [...activeChips];
    const index = newChipList.indexOf(item);

    if (index > -1) {
      newChipList.splice(index, 1);
      newChipList.push(getRandomActivity());
    }

    setActivityChips(newChipList);
  };

  const goToActivityPage = (item) => {
    const variantId = getVariantIdByActivityTitle(item);
    const categoryName = getCategoryNameByVariantId(variantId);

    history.push(`new/${categoryName}/${variantId}`);
  };

  useEffect(() => {
    const getLocallyStoredData = getLocallyStoredDataWithKey(localStorageNameSuffix);
    const activityListFromLocalStorage = getLocallyStoredData?.split(',');

    setActivityChips((getLocallyStoredData && activityListFromLocalStorage) || defaultActivityList);
  }, []);

  useEffect(() => {
    setLocalDataWithKey(localStorageNameSuffix, activeChips);
  }, [activeChips]);

  return (
    <ActivityChipListWrapper hasBeenDeletedOnce={hasBeenDeletedOnce}>
      {activeChips.map((item) => {
        const { name, category } = badgesMetadata[activeChips[activeChips?.indexOf(item)]];

        return (
          <ActivityChip
            key={item}
            label={name}
            id={name}
            onDelete={() => handleToogle(item)}
            onClick={() => goToActivityPage(item)}
            type={category}
          />
        );
      })}
    </ActivityChipListWrapper>
  );
};

ActivityChipList.propTypes = {
  history: shape({
    push: func.isRequired
  }).isRequired
};
