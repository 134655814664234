const sideHustle = {
  role: {
    name: ''
  },
  employer: {
    name: ''
  },
  workingLocation: {
    originalText: '',
    shortText: ''
  },
  primaryIndustryId: '',
  hoursPerWeek: '',
  description: '',
  motivation: {
    passion: false,
    income: false,
    learning: false,
    fun: false
  }
};

export default sideHustle;
