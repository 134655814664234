import React, { useState, useEffect } from 'react';
import {
  number,
  shape,
  string,
  bool,
  oneOf,
  func,
  oneOfType
} from 'prop-types';
import {
  commitmentOptionList,
  courseOptionList,
  commitmentUnionTypes
} from '@paddl/utils-js';
import Grid from '@mui/material/Grid';
import { AutocompleteSelect, Dropdown, Checkbox } from '@paddl/storybook';
import { updateValueByPathLookup, handleSuggestionList, sanitizeSelectedValue } from '../../utils';

const MasterDegree = ({
  cancelButton,
  continueButton,
  formSchema,
  data,
  data: {
    details: {
      educator = {},
      campus = {},
      course = {},
      courseDuration,
      commitment,
      attendanceOnline,
      attendanceOnCampus
    }
  }
}) => {
  const [newData, setNewData] = useState(data);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [optionsCourse, setOptionsCourse] = useState([]);
  const [optionsEducator, setOptionsEducator] = useState([]);
  const [optionsCampuses, setOptionsCampuses] = useState([]);

  const handleChange = (stringPath) => {
    const updatedData = updateValueByPathLookup(newData, stringPath);

    if (!isDirty) setIsDirty(true);
    if (JSON.stringify(newData) !== JSON.stringify(updatedData)) setNewData(updatedData);
  };

  useEffect(() => {
    if (isDirty && formSchema.isValidSync(newData)) setIsDisabled(false);
    if (isDirty && !formSchema.isValidSync(newData)) setIsDisabled(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, formSchema]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Course Name"
            data-test-id="course-name"
            placeholder="e.g Masters of Innovation"
            defaultValue={course.name}
            options={optionsCourse}
            action={(item) => handleChange([
              { path: 'details.course.name', value: (item && sanitizeSelectedValue(item)) || null },
              { path: 'details.course.id', value: (item && item.value) || null }
            ])}
            resetFieldAfterAction={false}
            getInputValue={async (value) => {
              setOptionsCourse(await handleSuggestionList(value, 'education/variants/master/courses'));
            }}
            allowCreateNewOption
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Educator Name"
            data-test-id="educator-name"
            placeholder="e.g Harvard University"
            defaultValue={educator.name}
            options={optionsEducator}
            action={(item) => handleChange([
              { path: 'details.educator.name', value: (item && sanitizeSelectedValue(item)) || null },
              { path: 'details.educator.id', value: (item && item.value) || null }
            ])}
            getInputValue={async (value) => setOptionsEducator(await handleSuggestionList(value, 'education/educators'))}
            resetFieldAfterAction={false}
            allowCreateNewOption
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Campus"
            data-test-id="campus"
            placeholder="e.g Cambridge"
            defaultValue={campus.name}
            options={optionsCampuses}
            action={(item) => handleChange([
              { path: 'details.campus.name', value: (item && sanitizeSelectedValue(item)) || null },
              { path: 'details.campus.id', value: (item && item.value) || null }
            ])}
            getInputValue={async (value) => setOptionsCampuses(await handleSuggestionList(value, 'education/campuses'))}
            resetFieldAfterAction={false}
            allowCreateNewOption
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={courseDuration}
            data-test-id="course-duration"
            label="Course Duration"
            placeholder="Duration"
            onChange={(item) => handleChange([
              { path: 'details.courseDuration', value: item }
            ])}
            items={courseOptionList}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={commitment}
            data-test-id="commitment"
            label="Commitment"
            placeholder="Commitment"
            onChange={(item) => handleChange([
              { path: 'details.commitment', value: item }
            ])}
            items={commitmentOptionList}
            isRequired
          />
        </Grid>
        <Grid item xs={12} className="checkbox-label">Attendance *</Grid>
        <Grid item xs={6}>
          <Checkbox
            data-test-id="on-campus"
            defaultChecked={attendanceOnCampus}
            label="On Campus"
            value={0}
            onChange={(isChecked) => handleChange([
              { path: 'details.attendanceOnCampus', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={6}>
          <Checkbox
            data-test-id="online"
            defaultChecked={attendanceOnline}
            label="Online"
            value={1}
            onChange={(isChecked) => handleChange([
              { path: 'details.attendanceOnline', value: isChecked }
            ])}
          />
        </Grid>
      </Grid>
      <div className="footer">
        {cancelButton ? cancelButton() : <div />}
        {continueButton(newData, isDisabled)}
      </div>
    </>
  );
};

MasterDegree.propTypes = {
  cancelButton: func.isRequired,
  continueButton: func.isRequired,
  formSchema: shape({}).isRequired,
  data: shape({
    details: shape({
      educator: shape({
        id: number,
        name: string
      }),
      campus: shape({
        id: number,
        name: string
      }),
      course: shape({
        id: number,
        name: string
      }),
      courseDuration: oneOfType([number, string]),
      commitment: oneOf([...commitmentUnionTypes, '']),
      attendanceOnline: bool,
      attendanceOnCampus: bool
    }).isRequired
  }).isRequired
};

export default MasterDegree;
