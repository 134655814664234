import React from 'react';
import {
  ActivityBadgeCard,
  ActivityVerificationDetails
} from '@paddl/storybook';
import { urlStringForPathKey } from '@paddl/utils-js';
import {
  string,
  number,
  shape
} from 'prop-types';
import { VerifiedPanelWrapper } from './Activity.styles';

export const ViewActivitySideContent = ({
  duration, activityTitle, xpNumber, metadata
}) => {
  return (<>
        <ActivityBadgeCard
          duration={duration}
          type={activityTitle}
          xpNumber={xpNumber}
        />
        <VerifiedPanelWrapper>
          <ActivityVerificationDetails
            authorName={metadata.fullName}
            isVerified={false}
            issueDate={metadata.createdAt}
            authorLink={urlStringForPathKey('profileById', {
              id: metadata.authorId
            })}
          />
        </VerifiedPanelWrapper>
          </>);
};

ViewActivitySideContent.propTypes = {
  duration: shape({}).isRequired,
  metadata: shape({ fullName: string, authorId: number }).isRequired,
  xpNumber: number.isRequired,
  activityTitle: string.isRequired
};
