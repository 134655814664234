const article = {
  provider: {
    id: null,
    name: ''
  },
  title: '',
  description: '',
  source: '',
  duration: {
    unit: 'MINUTE',
    value: ''
  }
};

export default article;
