import React, { useState, useEffect } from 'react';
import {
  number,
  shape,
  string,
  bool,
  func,
  oneOfType
} from 'prop-types';
import { primaryIndustryOptionList } from '@paddl/utils-js';
import Grid from '@mui/material/Grid';
import {
  TextField,
  Dropdown,
  Checkbox
} from '@paddl/storybook';
import {
  updateValueByPathLookup
} from '../../utils';

const UNITS_OPTIONS = [
  { text: 'Minutes', value: 'minutes' },
  { text: 'Hours', value: 'hours' }
];

export const Workshop = ({
  cancelButton,
  continueButton,
  formSchema,
  data,
  data: {
    details: {
      provider,
      description,
      name,
      commitmentMinutes,
      primaryIndustryId,
      attendance: {
        online,
        inPerson
      }
    }
  } = {}
}) => {
  const [newData, setNewData] = useState(data);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [commitment, setCommitment] = useState(commitmentMinutes);
  const [commitmentUnit, setCommitmentUnit] = useState(UNITS_OPTIONS[0].value);

  const descriptionLength = newData.details.description.length;

  const handleChange = (stringPath) => {
    const updatedData = updateValueByPathLookup(newData, stringPath);

    if (!isDirty) setIsDirty(true);
    if (JSON.stringify(newData) !== JSON.stringify(updatedData)) setNewData(updatedData);
  };

  useEffect(() => {
    if (isDirty && formSchema.isValidSync(newData)) setIsDisabled(false);
    if (isDirty && !formSchema.isValidSync(newData)) setIsDisabled(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, formSchema]);

  const commitmentIsHours = commitmentUnit === 'hours';

  useEffect(() => {
    handleChange([{ path: 'details.commitmentMinutes', value: commitmentIsHours ?  commitment * 60 : commitment }]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commitmentIsHours, commitment, commitmentMinutes]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Workshop Title"
            placeholder="e.g Productivity in the Workplace"
            onChange={(item) => handleChange([{
              path: 'details.session',
              value: {
                name: item,
                id: null
              }
            }])}
            defaultValue={name}
            isRequired
          />

          <TextField
            label="Provider"
            placeholder="Search or add a Provider"
            onChange={(item) => handleChange([{
              path: 'details.provider',
              value: {
                name: item,
                id: null,
                profileId: null,
                icon: null
              }
            }])}
            defaultValue={provider.name}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Duration"
            data-test-id="duration"
            placeholder="1"
            onChange={(item) => setCommitment(item)}
            defaultValue={commitmentMinutes ? commitmentMinutes.toString() : ''}
            maxLength={2}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Dropdown
            defaultValue={UNITS_OPTIONS[0].value}
            label="Unit"
            data-test-id="unit"
            placeholder="Unit"
            onChange={(item) => setCommitmentUnit(item)}
            items={UNITS_OPTIONS}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={primaryIndustryId}
            data-test-id="primary-industry"
            label="Primary Industry"
            placeholder="Accounting"
            onChange={(item) => handleChange([
              { path: 'details.primaryIndustryId', value: item }
            ])}
            items={primaryIndustryOptionList()}
          />
        </Grid>
        <Grid item xs={6}>
          <Checkbox
            defaultChecked={inPerson}
            data-test-id="virtual"
            label="In Person"
            value={0}
            onChange={(isChecked) => handleChange([
              { path: 'details.attendance.inPerson', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={6}>
          <Checkbox
            defaultChecked={online}
            data-test-id="online"
            label="Online"
            value={0}
            onChange={(isChecked) => handleChange([
              { path: 'details.attendance.online', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description (Optional)"
            placeholder="Tips and techniques on modern work..."
            maxLength={1500}
            onChange={(item) => handleChange([
              { path: 'details.description', value: item }
            ])}
            defaultValue={description}
            multiline
            isOutlined
            helperText={`Max 1500${descriptionLength > 0 ? `/${descriptionLength}` : ''} Characters`}
          />
        </Grid>
      </Grid>
      <div className="footer">
        {cancelButton ? cancelButton() : <div />}
        {continueButton(newData, isDisabled)}
      </div>
    </>
  );
};

Workshop.propTypes = {
  cancelButton: func.isRequired,
  continueButton: func.isRequired,
  formSchema: shape({}).isRequired,
  data: shape({
    details: shape({
      session: shape({
        id: number,
        name: string
      }).isRequired,
      provider: shape({
        id: number,
        name: string
      }).isRequired,
      attendance: shape({
        online: bool,
        inPerson: bool
      }).isRequired,
      description: string,
      primaryIndustryId: oneOfType([number, string]),
      commitmentMinutes: oneOfType([number, string])
    }).isRequired
  }).isRequired
};

export default Workshop;
