const phd = {
  educator: {
    name: ''
  },
  campus: {
    name: ''
  },
  title: {
    name: ''
  },
  thesisTitle: '',
  thesisDescription: '',
  duration: '',
  commitment: ''
};

export default phd;
