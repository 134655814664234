/* eslint-disable no-nested-ternary, react/jsx-closing-bracket-location */
import {
  badgesMetadata,
  ExpandablePageActionButton,
  ModularPage
} from '@paddl/storybook';
import {
  activityDictionary,
  getActivityIsProgressDisabledByVariantId,
  getActivityName,
  getActivityProgressTypeByVariantId,
  getActivityTitleByVariantId,
  getDecodedUserSessionCookie,
  trackEvent
} from '@paddl/utils-js';
import {
  withDataSubmission, MemberAppNavigation_Populated
} from '@paddl/utils-react';
import {
  func,
  shape,
  string
} from 'prop-types';
import React, {
  useContext, useEffect, useMemo, useState
} from 'react';
import { compose } from 'recompose';
import { defaultStatesFactory } from './DetailsSection/defaultStatesFactory';

import { ActivityFormContext } from './ActivityFormContext';
import { NewActivityNamePageContent } from './NewActivityNamePageContent';
import { SideContent } from './SideContent';

const decodedCookie = getDecodedUserSessionCookie();
const profileId = (decodedCookie && decodedCookie.profile_id) || '';

const newDate = new Date();
const day = newDate.getDate();
const month = newDate.getMonth() + 1;
const year = newDate.getFullYear();

const trackActivitySubmission = ({
  activityTitle,
  category,
  skillset,
  xpNumber,
  submittedDetails
}) => {
  trackEvent('Added an Activity', {
    activityType: activityTitle,
    activityCategory: category,
    activityName: getActivityName(submittedDetails) || submittedDetails?.eventId,
    xpEarned: xpNumber,
    skillsTagged: skillset
      ? skillset.map(({ name }) => name).toString()
      : ''
  });
};

const dirtyFormsCount = (isDirty) => {
  let count = 0;

  Object.keys(isDirty).map((item) => {
    if (isDirty[item]) count += 1;

    return count;
  });

  return count;
};

// eslint-disable-next-line react/prop-types
const SubmitActivityButton = ({ handleSubmitData }) => {
  const { sectionCompletedCount } = useContext(ActivityFormContext);

  return (<ExpandablePageActionButton icon="tickBold" expandedText="Submit" onClick={handleSubmitData} disabled={sectionCompletedCount !== 3} variant="primary" />);
};

export const NewActivityNameComp = ({
  match: {
    params: {
      category,
      variantId
    }
  },
  createActivity: {
    submit: submitActive,
    response: postRequestResponse
  },
  createBookmark: {
    submit: createNewBookmark,
    response: createNewBookmarkResponse
  },
  getSkillsSuggestion: {
    submit: submitSkillsSuggestion,
    response: postSkillsSuggestionResponse
  },
  history
}) => {
  if (!activityDictionary[variantId]) window.location.href = '/not-found';

  const { defaultFormStates: details } = defaultStatesFactory(variantId);
  const progressType = getActivityProgressTypeByVariantId(variantId);
  const isProgressDisabled = getActivityIsProgressDisabledByVariantId(variantId);
  const activityTitle = getActivityTitleByVariantId(variantId);
  const activityName = badgesMetadata[activityTitle]?.name;

  const activityMock = {
    details,
    duration: {
      startDate: { day, month, year },
      ...(progressType === 'TIME_STAMP' ? { timeStamp: { day, month, year } } : {}),
      endDate: { day, month, year },
      isCurrent: false
    },
    id: null,
    percentageComplete: null,
    profileId,
    skillset: [],
    variantId: parseInt(variantId, 10),
    xpNumber: 0
  };

  const [edit, setEdit] = useState('details');
  const [data, setData] = useState(activityMock);
  const [mediaUrl, setMediaUrl] = useState(undefined);
  const [isMediaDirty, setIsMediaDirty] = useState(false);
  const [hasSkillsSuggestionBeenRequested, setHasSkillsSuggestionBeenRequested] = useState(false);
  const [isDirty, setIsDirty] = useState({
    details: false,
    progress: isProgressDisabled,
    skills: false
  });

  const updateMediaCallback = (updatedMediaUrl, updatedMediaDescription) => {
    setMediaUrl(updatedMediaUrl);

    if (updatedMediaDescription && !hasSkillsSuggestionBeenRequested) {
      submitSkillsSuggestion(`${process.env.NLP_API}/tokens-extractor`, {
        text: updatedMediaDescription
      });

      setHasSkillsSuggestionBeenRequested(true);
    }

    if (!updatedMediaDescription && hasSkillsSuggestionBeenRequested) {
      setHasSkillsSuggestionBeenRequested(false);
    }
  };

  const handleSubmitData = () => submitActive(`${process.env.API_PREFIX_V2}/activities`, data);

  useEffect(() => {
    if (isDirty && mediaUrl && postRequestResponse?.id && !createNewBookmarkResponse.errorMessage && !isMediaDirty) {
      createNewBookmark(`${process.env.ACTIVITY_MEDIA_API_V1}/bookmark`, {
        resource_url: mediaUrl,
        activity_id: postRequestResponse.id.toString()
      });

      setIsMediaDirty(true);
      setMediaUrl(undefined);
    }

    if (isDirty && postRequestResponse?.id && !mediaUrl) {
      const { skillset, details: submittedDetails } = data;

      trackActivitySubmission({
        xpNumber: postRequestResponse.xpNumber,
        activityTitle,
        category,
        skillset,
        submittedDetails
      });

      // TODO: display snackbar with SUCCESS message before page redirect
      if (isMediaDirty) {
        history.push(`/activities/${postRequestResponse.id}?media=true`);
      } else {
        history.push(`/activities/${postRequestResponse.id}`);
      }
    }

    if (isDirty && (postRequestResponse?.errorMessage || createNewBookmarkResponse?.errorMessage)) {
      console.error(postRequestResponse.errorMessage || createNewBookmarkResponse?.errorMessage.message);
      // TODO: display snackbar with ERROR message before page reload
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDirty,
    postRequestResponse,
    mediaUrl,
    history,
    data,
    activityTitle,
    category
  ]);

  useEffect(() => window.scrollTo(0, 0), []);

  const context = useMemo(() => {
    const sectionCompletedCount = dirtyFormsCount(isDirty);

    return {
      data, setData, isDirty, setIsDirty, edit, setEdit, variantId, sectionCompletedCount
    };
  }, [data, setData, isDirty, setIsDirty, edit, setEdit, variantId]);

  const breadcrumbItems = [
    {
      title: 'Add Activity',
      action: () => history.push('/activities/new')
    },
    {
      title: category,
      action: () => history.push(`/activities/new/${category}`)
    },
    {
      title: `${activityName}`
    }
  ];

  return (
    <ActivityFormContext.Provider value={context}>
    <ModularPage
      navigationContent={<MemberAppNavigation_Populated currentPage="activity" />}
      pageName={`Add a ${activityName}`}
      pageContent={<NewActivityNamePageContent
        handleSubmitData={handleSubmitData}
        postSkillsSuggestionResponse={postSkillsSuggestionResponse}
        updateMediaCallback={updateMediaCallback}
  />}
      sidebarContent={<SideContent category={category} />}
      breadcrumbItems={breadcrumbItems}
      pageActionItems={[
        <SubmitActivityButton handleSubmitData={handleSubmitData} />
      ]}

    />

    </ActivityFormContext.Provider>
  );
};

NewActivityNameComp.propTypes = {
  match: shape({
    params: shape({
      variantId: string.isRequired
    }).isRequired
  }).isRequired,
  createActivity: shape({
    submit: func.isRequired,
    response: shape({}).isRequired
  }).isRequired,
  createBookmark: shape({
    submit: func.isRequired,
    response: shape({}).isRequired
  }).isRequired,
  getSkillsSuggestion: shape({
    submit: func.isRequired,
    response: shape({}).isRequired
  }).isRequired,
  history: shape({
    push: func.isRequired
  }).isRequired
};

export const NewActivityName = compose(
  withDataSubmission({ propNameWrapper: 'createActivity' }),
  withDataSubmission({ propNameWrapper: 'createBookmark' }),
  withDataSubmission({ propNameWrapper: 'getSkillsSuggestion' })
)(NewActivityNameComp);
