const membership = {
  role: {
    name: ''
  },
  organisation: {
    name: ''
  },
  primaryIndustryId: '',
  hoursPerWeek: '',
  description: '',
  responsibility: {
    subscriber: false,
    member: false,
    organiser: false,
    leader: false
  }
};

export default membership;
