/* eslint-disable default-param-last */
import { CountryFlags } from '@paddl/storybook';
import axios from 'axios';

export const stringPathToArray = (path) => (Array.isArray(path) ? path : path.split('.'));

export const updateValueByPathLookup = (sourceObject = {}, newValues = []) => {
  if (!Object.keys(sourceObject).length || !newValues.length) {
    throw new Error('updateValueByPathLookup function expects valid arguments');
  }

  const clonedData = JSON.parse(JSON.stringify(sourceObject));

  newValues.forEach(({ path, value }) => {
    const arrayPath = stringPathToArray(path);

    // TODO: Change below hardcoded conditions over a recursive object path look up
    if (!arrayPath.length || arrayPath.length > 3) {
      throw new Error('Path is over three level deep!');
    }
    if (arrayPath.length === 1) {
      clonedData[arrayPath[0]] = value;
    } else if (arrayPath.length === 2) {
      clonedData[arrayPath[0]][arrayPath[1]] = value;
    } else if (arrayPath.length === 3) {
      clonedData[arrayPath[0]][arrayPath[1]][arrayPath[2]] = value;
    }
  });

  return clonedData;
};

const sortedCountryFlags = Object.keys(CountryFlags).sort().reduce((result, key) => {
  result[key] = CountryFlags[key]; // eslint-disable-line no-param-reassign

  return result;
}, {});

export const getCountryList = () =>
  Object.keys(sortedCountryFlags).map((index) => {
    const countryName = sortedCountryFlags[index].name;

    return { value: countryName, text: countryName };
  });

export const getSuggestionList = async (value = '', endpointPathName) => {
  try {
    const endpoint = `${process.env.API_PREFIX_V2}/${endpointPathName}/suggestions?substring=${value}`;
    const res = await axios.get(endpoint);

    return res;
  } catch (err) {
    console.error(err);
  }

  return null;
};

// To specifically remove the "Use " keyword that Material UI's AutocompleteSelect
// component prepend when selecting a brand new value
export const sanitizeSelectedValue = (item) => {
  if (item.title && item.value) return item.title;

  return item.title.split(' ').slice(1).join(' ');
};

export const handleSuggestionList = async (value = '', endpointPathName) => (
  getSuggestionList(value, endpointPathName).then(({
    data
  }) => data.map(({
    id,
    name
  }) => ({
    value: id,
    title: name
  }))).catch(() => [])
);

export const humanisedErrorMessage = (message) => (
  ((message && message.indexOf('ENOTFOUND') > -1) ||
   (message && message.indexOf('ECONNREFUSED') > -1) ||
   (message && message.indexOf('denied') > -1))
    ? 'The link provided is not reachable.'
    : message
);
