import { getVariantIdByActivityTitle } from '@paddl/utils-js';

export const getProgressStartLabel = (variantId, progressType) => {
  if (progressType !== 'TIME_STAMP') return 'Start';
  if (variantId === getVariantIdByActivityTitle('prize')) {
    return 'Date Received';
  }

  return 'Date Completed';
};
