import * as Yup from 'yup';

const article = Yup.object().shape({
  details: Yup.object().shape({
    provider: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    title: Yup.string().required(),
    duration: Yup.object().shape({
      unit: Yup.string().required(),
      value: Yup.number().required()
    }).defined()
  })
}).defined();

export default article;
