const hackathon = {
  event: {
    name: ''
  },
  organiser: {
    name: '',
    profileId: null,
    location: {
      placeId: null
    }
  },
  sourceIcon: '',
  sourceName: '',
  eventDuration: {
    unit: '',
    value: ''
  },
  eventImg: '',
  eventLink: 'http:',
  eventLocation: {
    originalText: '',
    shortText: '',
    placeId: null
  },
  competitionPlace: null,
  challengeDescription: ''
};

export default hackathon;
