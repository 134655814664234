import * as Yup from 'yup';
import { atLeastOneOfBoolean } from '../../addMethods.yup';

atLeastOneOfBoolean();

const paddlGames = Yup.object().shape({
  details: Yup.object().shape({
    competitionPlace: Yup.number().required(),
    roleCreative: Yup.boolean(),
    roleDemonstrator: Yup.boolean(),
    rolePresenter: Yup.boolean(),
    roleTeamLeader: Yup.boolean(),
    teamName: Yup.string()
  }).atLeastOneOfBoolean([
    'roleCreative',
    'roleDemonstrator',
    'rolePresenter',
    'roleTeamLeader'
  ])
}).defined();

export default paddlGames;
