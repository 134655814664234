import * as Yup from 'yup';

const prize = Yup.object().shape({
  details: Yup.object().shape({
    name: Yup.string().required(),
    issuer: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    categoryId: Yup.number().required(),
    jurisdictionId: Yup.number().required(),
    description: Yup.string().nullable()
  })
}).defined();

export default prize;
