/* eslint-disable react/jsx-no-useless-fragment */
import React, { createElement } from 'react';
import {
  func,
  shape,
  string,
  bool
} from 'prop-types';
import {
  Modal,
  Badges,
  ShareLink,
  LogoButton,
  SocialMediaShare,
  Colors
} from '@paddl/storybook';
import {
  getAddToLinkedinProfileUrl,
  trackEvent,
  getActivityName,
  getCategoryNameByVariantId,
  urlStringForPathKey
} from '@paddl/utils-js';
import {
  ShareModalHeader,
  ShareModalContentWrapper,
  AddToLinkedinIntro,
  AddToLinkedinWrapper
} from './ShareModal.styles';

export const ShareModal = ({
  isShareModalOpen,
  setIsShareModalOpen,
  activityTitle,
  activityData
}) => {
  if (!activityTitle || !Object.keys(activityData).length > 0) {
    return <></>;
  }

  const sharedTrackingData = {
    activityType: activityTitle,
    activityCategory: getCategoryNameByVariantId(activityData.variantId),
    activityName: getActivityName(activityData.details) || activityData.details?.eventId
  };

  return (
    <Modal
      hasBrandedColour={false}
      isOpen={isShareModalOpen}
      handleExit={() => setIsShareModalOpen(false)}
      data-test-id="share-modal"
      hasOverflow
    >
    <ShareModalHeader>
      <div className="badge-category-icon">
        {createElement(Badges[activityTitle])}
      </div>
      <h2>Share Activity</h2>
    </ShareModalHeader>
      <ShareModalContentWrapper>
        <ShareLink
          url={urlStringForPathKey('publicActivitiesById', { id: activityData.id })}
          onClick={() => {
            trackEvent('Shared Activity', {
              ...sharedTrackingData,
              shareType: 'link'
            });
          }}
        />
          <AddToLinkedinWrapper>
            <AddToLinkedinIntro>Add as a credential on LinkedIn</AddToLinkedinIntro>
            <LogoButton
              fullWidth
              icon="linkedinNegative"
              iconBackground={Colors.white}
              onClick={() => {
                trackEvent('Shared Activity', {
                  ...sharedTrackingData,
                  shareType: 'linkedinCredentials'
                });

                window.open(getAddToLinkedinProfileUrl(activityData), '_blank');
              }}
            >
              Add to profile
            </LogoButton>
          </AddToLinkedinWrapper>
          <SocialMediaShare
            url={urlStringForPathKey('publicActivitiesById', { id: activityData.id })}
            onClick={(provider) => {
              trackEvent('Shared Activity', {
                ...sharedTrackingData,
                shareType: provider
              });
            }}
          />
      </ShareModalContentWrapper>
    </Modal>
  );
};

ShareModal.propTypes = {
  isShareModalOpen: bool.isRequired,
  setIsShareModalOpen: func.isRequired,
  activityTitle: string,
  activityData: shape({})
};

ShareModal.defaultProps = {
  activityTitle: '',
  activityData: {}
};
