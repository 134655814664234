const video = {
  provider: {
    id: null,
    name: ''
  },
  title: '',
  description: '',
  source: '',
  duration: {
    unit: '',
    value: ''
  }
};

export default video;
