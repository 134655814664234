import styled from '@emotion/styled';

export const ActivityChipListWrapper = styled.div`

  display: flex;
  justify-content: center;
  gap: 24px;
  row-gap: 16px;
  flex-wrap: wrap;

  @keyframes fadeIn {
    0% { 
      display: none;
      transform: scale(.85);
      opacity: 0;
    }
    60% { 
      display: block;
      transform: scale(.85);
      opacity: 0;
    }
    100% { 
      display: block;
      transform: scale(1);
      opacity: 1;
    }
  };

  text-align: center;
  margin-bottom: 20px;

  .activity-chip {
    &:last-child {
      animation-name: ${({ hasBeenDeletedOnce }) => hasBeenDeletedOnce && 'fadeIn'};
      animation-duration: .85s;
      transition-timing-function: ease-out;
    }
  }
`;
