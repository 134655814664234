import { useState, useEffect, useRef } from 'react';
import { shape, func } from 'prop-types';
import { compose } from 'recompose';
import { withContenfulClient } from '../../../../hoc/withContenfulClient';
import { updateValueByPathLookup } from '../../utils';

export const PaddlGamesComp = ({
  contentfulClient: {
    fetch,
    isSuccess,
    response
  },
  data,
  data: {
    details: {
      eventId
    }
  },
  updateDataCallback
}) => {
  const [contentfulResponse, setContentfulResponse] = useState([]);

  const hasUpdated = useRef(false);

  useEffect(() => {
    if (!hasUpdated.current) {
      fetch({ content_type: 'paddlGames', 'fields.eventId': eventId });
      hasUpdated.current = true;
    }

    if (hasUpdated.current && isSuccess && response?.items[0]) {
      const {
        name: title,
        hostCompany,
        location,
        challengeFocus
      } = response.items[0].fields;
      const stringPath = [{
        path: 'contentfulFields',
        value: {
          title,
          hostCompany,
          location,
          challengeFocus
        }
      }];
      const updatedData = updateValueByPathLookup(data, stringPath);

      if (Object.keys(contentfulResponse).length === 0) {
        setContentfulResponse(updatedData);
        updateDataCallback(updatedData);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, isSuccess, response, eventId]);

  return null;
};

PaddlGamesComp.propTypes = {
  contentfulClient: shape({
    fetch: func.isrequired,
    response: shape({}).isRequired
  }).isRequired,
  data: shape({}).isRequired,
  updateDataCallback: func.isRequired
};

const PaddlGames = compose(
  withContenfulClient({ propNameWrapper: 'contentfulClient' })
)(PaddlGamesComp);

export default PaddlGames;
