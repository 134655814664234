import React, {
  useState,
  useEffect
} from 'react';
import {
  shape,
  func,
  bool,
  string,
  number
} from 'prop-types';
import {
  ActivityMediaWrapper,
  ConfirmationModal,
  BookmarkPreview
} from '@paddl/storybook';
import { compose } from 'recompose';
import { withDataFetching, withDataSubmission } from '@paddl/utils-react';
import { ActivityMediaForm } from '../../../../components/ActivityMediaForm';

const MAX_RETRIES = 10;

export const DefaultMediaComp = ({
  getResources: {
    fetch,
    isSuccess,
    isLoading,
    response: { response }
  },
  deleteResource: {
    fetch: deleteResource,
    response: deleteRequestResponse
  },
  createBookmark: {
    submit: createNewBookmark,
    isLoading: createNewBookmarkLoading,
    response: createNewBookmarkResponse
  },
  activityName,
  isViewingOwnActivity,
  data: {
    id: activityId
  },
  categoryName
}) => {
  const [bookmark, setBookmark] = useState(undefined);
  const [url, setUrl] = useState(undefined);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const doneTrying = isSuccess || retryCount >= MAX_RETRIES;

  const deleteCallback = () => setIsConfirmModalOpen(true);

  const handleCloseModal = () => setIsConfirmModalOpen(false);

  const handleDeleteResource = (resourceId) =>
    deleteResource(`${process.env.ACTIVITY_MEDIA_API_V1}/resource/${resourceId}`, 'delete');

  const handleCreateBookmark = () =>
    createNewBookmark(`${process.env.ACTIVITY_MEDIA_API_V1}/bookmark`, {
      resource_url: url,
      activity_id: activityId.toString()
    });

  const handlePressEnter = async (event) => {
    const eventCode = event?.code?.toLowerCase();

    if (eventCode === 'enter' || eventCode === 'numpadenter' || event?.keyCode === 13) {
      setBookmark(undefined);
      handleCreateBookmark();
      event.preventDefault();
    }
  };

  const renderActivityMediaContent = () => {
    const activityMediaContentIsLoading = (isLoading || createNewBookmarkLoading || !doneTrying);

    const noBookmarkFound = !activityMediaContentIsLoading && !bookmark;
    if (noBookmarkFound && isViewingOwnActivity) {
      return (<ActivityMediaForm
        handlePressEnter={handlePressEnter}
        activityName={activityName}
        setUrl={setUrl}
        hasError={Boolean(createNewBookmarkResponse?.errorMessage)}
        errorText={createNewBookmarkResponse?.errorMessage?.message}
      />);
    }

    if (activityMediaContentIsLoading || bookmark) {
      return <BookmarkPreview
        image={bookmark?.metadata?.image}
        title={bookmark?.metadata?.title}
        url={bookmark?.origin_url || url}
        logo={bookmark?.metadata?.logo}
        description={bookmark?.metadata?.description}
        isLoading={activityMediaContentIsLoading}
        isDeletable={isViewingOwnActivity}
        deleteCallback={deleteCallback}
      />;
    }

    return null;
  };

  useEffect(() => {
    if (!isLoading && !isSuccess && retryCount < MAX_RETRIES) {
      setTimeout(() => {
        fetch(`${process.env.ACTIVITY_MEDIA_API_V1}/activity/${activityId}`);
        setRetryCount(retryCount + 1);
      }, 500);
    }
  }, [isSuccess, isLoading, fetch, activityId, retryCount]);

  useEffect(() => {
    if (!isLoading && isSuccess && response[0]) {
      const bookmarkResource = response.find(({ resource_type }) => resource_type === 'bookmark');
      setBookmark(bookmarkResource);
    }
  }, [isSuccess, isLoading, fetch, activityId, response]);

  useEffect(() => {
    if (deleteRequestResponse?.resource_type === 'bookmark') setBookmark(undefined);
  }, [deleteRequestResponse]);

  useEffect(() => {
    if (createNewBookmarkResponse?.resource_id) setBookmark(createNewBookmarkResponse);
  }, [createNewBookmarkResponse]);

  return (
    <>
      {isViewingOwnActivity &&
        <>
          <ActivityMediaWrapper category={categoryName || 'education'}>
            {renderActivityMediaContent()}
          </ActivityMediaWrapper>
          <ConfirmationModal
            isOpen={isConfirmModalOpen}
            handleExit={() => handleCloseModal()}
            confirmButtonCallback={() => {
              setBookmark(undefined);
              handleDeleteResource(bookmark?.resource_id);
              handleCloseModal();
            }}
            contentText="Removing a Bookmark cannot be undone."
          />
        </>}
      {!isViewingOwnActivity && bookmark &&
        <ActivityMediaWrapper category={categoryName || 'education'}>
          {renderActivityMediaContent()}
        </ActivityMediaWrapper>}
    </>
  );
};

DefaultMediaComp.propTypes = {
  getResources: shape({
    fetch: func.isrequired,
    isSuccess: bool.isRequired,
    isLoading: bool.isRequired
  }).isRequired,
  deleteResource: shape({
    fetch: func.isRequired
  }).isRequired,
  createBookmark: shape({
    submit: func.isRequired,
    isLoading: bool.isRequired
  }).isRequired,
  isViewingOwnActivity: bool.isRequired,
  activityName: string.isRequired,
  data: shape({
    id: number.isRequired
  }).isRequired,
  categoryName: string.isRequired
};

const DefaultMedia = compose(
  withDataFetching({ propNameWrapper: 'getResources' }),
  withDataFetching({ propNameWrapper: 'deleteResource' }),
  withDataSubmission({ propNameWrapper: 'createBookmark' })
)(DefaultMediaComp);

export default DefaultMedia;
