import * as Yup from 'yup';
import { atLeastOneOfBoolean } from '../../addMethods.yup';

atLeastOneOfBoolean();

const volunteer = Yup.object().shape({
  details: Yup.object().shape({
    role: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    organisation: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    volunteerLocation: Yup.object().shape({
      originalText: Yup.string().required(),
      shortText: Yup.string()
    }).defined(),
    primaryIndustryId: Yup.number().required(),
    hoursPerWeek: Yup.number().required()
      .test('range', 'Total should range between 1 and 99', (value) => value >= 1 && value <= 99),
    purpose: Yup.object().shape({
      charity: Yup.boolean().required(),
      support: Yup.boolean().required(),
      experience: Yup.boolean().required(),
      community: Yup.boolean().required()
    }),
    description: Yup.string().nullable()
  })
}).defined();

export default volunteer;
