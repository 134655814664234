import React, { useState, useEffect } from 'react';
import {
  number,
  shape,
  string,
  bool,
  func,
  oneOfType
} from 'prop-types';
import Grid from '@mui/material/Grid';
import {
  TextField,
  Checkbox,
  AutocompleteSelect,
  Dropdown
} from '@paddl/storybook';
import {
  initGoogleLocationApi,
  fetchLocationSuggestions,
  meetupTypesOptionList,
  meetupAttendeesOptionList
} from '@paddl/utils-js';
import {
  updateValueByPathLookup
} from '../../utils';

export const Meetup = ({
  cancelButton,
  continueButton,
  formSchema,
  data,
  data: {
    details: {
      name = {},
      organiser = {},
      meetupLocation = {},
      commitmentHours,
      description,
      isVirtual,
      isHost,
      typeId,
      attendeesId
    }
  }
}) => {
  const [newData, setNewData] = useState(data);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [currentAddress, setCurrentAddress] = useState(null);

  const descriptionLength = newData.details.description.length;

  const handleChange = (stringPath) => {
    const updatedData = updateValueByPathLookup(newData, stringPath);

    if (!isDirty) setIsDirty(true);
    if (JSON.stringify(newData) !== JSON.stringify(updatedData)) setNewData(updatedData);
  };

  const handleLocations = (address = '') => {
    fetchLocationSuggestions(address, (locations) => {
      if (currentAddress !== address) {
        setCurrentAddress(address);
        setLocationSuggestions(locations);
      }
    });
  };

  useEffect(() => {
    if (isDirty && formSchema.isValidSync(newData)) setIsDisabled(false);
    if (isDirty && !formSchema.isValidSync(newData)) setIsDisabled(true);
    if (typeof window.google === 'undefined') initGoogleLocationApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, formSchema]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Name"
            placeholder="e.g StartUp Society Member"
            onChange={(item) => handleChange([{ path: 'details.name', value: item }])}
            defaultValue={name}
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Organiser"
            data-test-id="organiser"
            placeholder="e.g  StartUp Society"
            onChange={(item) => handleChange([{
              path: 'details.organiser',
              value: {
                name: item,
                id: null,
                profileId: null,
                icon: null
              }
            }])}
            defaultValue={organiser.name}
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Meetup Location"
            action={(item) => {
              const location = item || '';
              handleChange([{
                path: 'details.meetupLocation',
                value: {
                  placeId: location.value,
                  originalText: location.title,
                  shortText: location.shortText,
                  lat: location.lat,
                  lng: location.lng
                }
              }]);
            }}
            defaultValue={meetupLocation.originalText}
            options={locationSuggestions}
            getInputValue={(value) => handleLocations(value)}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Committed Hours"
            data-test-id="commitment"
            placeholder="40"
            onChange={(item) => handleChange([{ path: 'details.commitmentHours', value: item }])}
            defaultValue={commitmentHours ? commitmentHours.toString() : ''}
            maxLength={2}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Dropdown
            defaultValue={typeId || ''}
            data-test-id="meetup-type"
            label="Meetup Type"
            placeholder="Meetup Type"
            onChange={(item) => handleChange([{ path: 'details.typeId', value: item }])}
            items={meetupTypesOptionList}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Dropdown
            defaultValue={attendeesId || ''}
            data-test-id="attendees"
            label="Attendees"
            placeholder="1-10"
            onChange={(item) => handleChange([
              { path: 'details.attendeesId', value: item }
            ])}
            items={meetupAttendeesOptionList}
          />
        </Grid>
        <Grid item xs={6}>
          <Checkbox
            defaultChecked={isVirtual}
            data-test-id="virtual"
            label="Virtual"
            value={0}
            onChange={(isChecked) => handleChange([
              { path: 'details.isVirtual', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={6}>
          <Checkbox
            defaultChecked={isHost}
            data-test-id="host"
            label="Host"
            value={0}
            onChange={(isChecked) => handleChange([
              { path: 'details.isHost', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description (Optional)"
            data-test-id="description"
            placeholder="Meetup description"
            maxLength={1500}
            onChange={(item) => handleChange([
              { path: 'details.description', value: item }
            ])}
            defaultValue={description}
            isOutlined
            multiline
            helperText={`Max 1500${descriptionLength > 0 ? `/${descriptionLength}` : ''} Characters`}
          />
        </Grid>
      </Grid>
      <div className="footer">
        {cancelButton ? cancelButton() : <div />}
        {continueButton(newData, isDisabled)}
      </div>
    </>
  );
};

Meetup.propTypes = {
  cancelButton: func.isRequired,
  continueButton: func.isRequired,
  formSchema: shape({}).isRequired,
  data: shape({
    details: shape({
      name: string,
      organiser: shape({
        id: number,
        name: string
      }).isRequired,
      meetupLocation: shape({
        originalText: string
      }),
      commitmentHours: oneOfType([number, string]),
      typeId: oneOfType([number, string]),
      attendeesId: oneOfType([number, string]),
      description: string,
      isVirtual: bool,
      isHost: bool
    }).isRequired
  }).isRequired
};

export default Meetup;
