import styled from '@emotion/styled';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import ButtonBase from '@mui/material/ButtonBase';
import { Colors, Row } from '@paddl/storybook';
import React from 'react';
import PropTypes from 'prop-types';

const ClickableArea = styled(ButtonBase)`
  width: 100%;
  padding: 32px 60px;
  border-radius: 4px;

  &:hover {
    background-color:  ${Colors.grey_magnolia};
  }
`;

export const EditableSectionWrapper = ({
  children,
  onEditClick,
  canEdit
}) =>
  <ClickableArea disabled={!canEdit} onClick={onEditClick}>
    <Row style={{ width: '100%', alignItems: 'flex-start' }}>
        <div style={{ flex: 1, textAlign: 'start' }}>{children}</div>
      {canEdit && <EditSharpIcon style={{ color: Colors.new_grey_light }} /> }
    </Row>
  </ClickableArea>;

EditableSectionWrapper.defaultProps = {
  onEditClick: () => {},
  canEdit: false
};

EditableSectionWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  onEditClick: PropTypes.func,
  canEdit: PropTypes.bool
};
