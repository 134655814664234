import * as Yup from 'yup';

const membership = Yup.object().shape({
  details: Yup.object().shape({
    role: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    organisation: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    primaryIndustryId: Yup.number().required(),
    hoursPerWeek: Yup.number().required()
      .test('range', 'Total should range between 1 and 99', (value) => value >= 1 && value <= 99),
    description: Yup.string().nullable(),
    responsibility: Yup.object().shape({
      subscriber: Yup.boolean().required(),
      member: Yup.boolean().required(),
      organiser: Yup.boolean().required(),
      leader: Yup.boolean().required()
    })
  })
}).defined();

export default membership;
