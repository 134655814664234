import { Artworks } from '@paddl/storybook';
import React, { useContext } from 'react';
import { shape, arrayOf, string } from 'prop-types';
import { FormHeader } from '../../../components';
import { SkillsEdit, skillsFormSchema } from '../../Activity/SkillsSection';
import { ActivityFormContext } from './ActivityFormContext';
import { CancelButton } from './CancelButton';
import { ContinueButton } from './ContinueButton';
import { EmptySection } from './EmptySection';
import { EditWrapper } from './NewActivityName.styles';

const { skills: Skills } = Artworks;

export const SkillsSection = ({ postSkillsSuggestionResponse }) => {
  const {
    isDirty, edit, setEdit, data
  } = useContext(ActivityFormContext);

  const renderContinueButton = (updatedData, isDisabled) => (<ContinueButton updatedData={updatedData} isDisabled={isDisabled} type="skills" />);
  const renderCancelButton = () => <CancelButton />;
  const skillsClassName = edit === 'skills' ? 'editable' : 'viewable';

  return (
    <section
      className={
      isDirty.skills
        ? skillsClassName
        : `${skillsClassName} empty-section-wrapper`
    }
    >
    {edit === 'skills' ? (
      <EditWrapper>
        <FormHeader
          icon={<Skills />}
          headline="Relevant Skills"
          subTitle="Add the skills that you developed during this Activity..."
        />
        <SkillsEdit
          data={{ ...data }}
          continueButton={renderContinueButton}
          cancelButton={renderCancelButton}
          formSchema={skillsFormSchema}
          skillsSuggestion={postSkillsSuggestionResponse?.tokens || []}
        />
      </EditWrapper>
    ) : (
      <EmptySection
        icon={<Skills />}
        headline="Relevant Skills"
        subTitle="Add the skills that you developed during this Activity..."
        setEdit={() => setEdit('skills')}
        isDirty={!!isDirty.skills}
      />
    )}
    </section>
  );
};

SkillsSection.defaultProps = {
  postSkillsSuggestionResponse: undefined
};

SkillsSection.propTypes = {
  postSkillsSuggestionResponse: shape({ tokens: arrayOf(string) })
};
