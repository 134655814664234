import React, { useState, useEffect } from 'react';
import {
  number,
  shape,
  string,
  bool,
  oneOf,
  func,
  oneOfType
} from 'prop-types';
import {
  commitmentOptionList,
  commitmentUnionTypes,
  activityDurationUnitOptionList,
  certificateTypeOptionList,
  certificateUnionTypes,
  getActivityDurationNumberOptionList
} from '@paddl/utils-js';
import Grid from '@mui/material/Grid';
import {
  AutocompleteSelect,
  Dropdown,
  Checkbox
} from '@paddl/storybook';
import {
  updateValueByPathLookup,
  getCountryList,
  handleSuggestionList,
  sanitizeSelectedValue
} from '../../utils';

const Certificate = ({
  cancelButton,
  continueButton,
  formSchema,
  data,
  data: {
    details: {
      authority = {},
      certificate = {},
      certificateDuration = {},
      commitment,
      location,
      certificateType,
      attendanceOnline,
      attendanceInPerson
    }
  }
}) => {
  const [newData, setNewData] = useState(data);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [optionsCertificate, setOptionsCertificate] = useState([]);
  const [optionsAuthority, setOptionsAuthority] = useState([]);

  const handleChange = (stringPath) => {
    const updatedData = updateValueByPathLookup(newData, stringPath);

    if (!isDirty) setIsDirty(true);
    if (JSON.stringify(newData) !== JSON.stringify(updatedData)) setNewData(updatedData);
  };

  useEffect(() => {
    if (isDirty && formSchema.isValidSync(newData)) setIsDisabled(false);
    if (isDirty && !formSchema.isValidSync(newData)) setIsDisabled(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, formSchema]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Authority"
            data-test-id="authority"
            placeholder="e.g Bar School"
            defaultValue={authority.name}
            options={optionsAuthority}
            action={(item) => handleChange([
              { path: 'details.authority.name', value: (item && sanitizeSelectedValue(item)) || null },
              { path: 'details.authority.id', value: (item && item.value) || null }
            ])}
            getInputValue={async (value) => setOptionsAuthority(await handleSuggestionList(value, 'education/authorities'))}
            resetFieldAfterAction={false}
            allowCreateNewOption
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Certificate Name"
            data-test-id="certificate-name"
            placeholder="e.g Responsible Serving of Alcohol"
            defaultValue={certificate.name}
            options={optionsCertificate}
            action={(item) => handleChange([
              { path: 'details.certificate.name', value: (item && sanitizeSelectedValue(item)) || null },
              { path: 'details.certificate.id', value: (item && item.value) || null }
            ])}
            resetFieldAfterAction={false}
            getInputValue={async (value) => {
              setOptionsCertificate(await handleSuggestionList(value, 'education/variants/certificate/certificates'));
            }}
            allowCreateNewOption
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={location}
            label="Country"
            data-test-id="country"
            placeholder="Country"
            onChange={(item) => handleChange([
              { path: 'details.location', value: item }
            ])}
            items={getCountryList()}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={certificateType}
            data-test-id="certificate-type"
            label="Certificate Type"
            placeholder="Certificate Type"
            onChange={(item) => handleChange([
              { path: 'details.certificateType', value: item }
            ])}
            items={certificateTypeOptionList}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Dropdown
            defaultValue={certificateDuration.value}
            data-test-id="duration"
            label="Duration"
            placeholder="Duration"
            onChange={(item) => handleChange([
              { path: 'details.certificateDuration.value', value: item }
            ])}
            items={getActivityDurationNumberOptionList()}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Dropdown
            defaultValue={certificateDuration.unit}
            data-test-id="unit"
            label="Unit"
            placeholder="Duration"
            onChange={(item) => handleChange([
              { path: 'details.certificateDuration.unit', value: item }
            ])}
            items={activityDurationUnitOptionList}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={commitment}
            data-test-id="commitment"
            label="Commitment"
            placeholder="Commitment"
            onChange={(item) => handleChange([
              { path: 'details.commitment', value: item }
            ])}
            items={commitmentOptionList}
            isRequired
          />
        </Grid>
        <Grid item xs={12} className="checkbox-label">Attendance *</Grid>
        <Grid item xs={6}>
          <Checkbox
            defaultChecked={attendanceInPerson}
            data-test-id="in-person"
            label="In Person"
            value={0}
            onChange={(isChecked) => handleChange([
              { path: 'details.attendanceInPerson', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={6}>
          <Checkbox
            defaultChecked={attendanceOnline}
            data-test-id="online"
            label="Online"
            value={1}
            onChange={(isChecked) => handleChange([
              { path: 'details.attendanceOnline', value: isChecked }
            ])}
          />
        </Grid>
      </Grid>
      <div className="footer">
        {cancelButton ? cancelButton() : <div />}
        {continueButton(newData, isDisabled)}
      </div>
    </>
  );
};

Certificate.propTypes = {
  cancelButton: func.isRequired,
  continueButton: func.isRequired,
  formSchema: shape({}).isRequired,
  data: shape({
    details: shape({
      authority: shape({
        id: number,
        name: string
      }),
      certificate: shape({
        id: number,
        name: string
      }),
      certificateDuration: shape({
        value: oneOfType([number, string]),
        unit: string
      }),
      location: string,
      certificateType: oneOf([...certificateUnionTypes, '']),
      commitment: oneOf([...commitmentUnionTypes, '']),
      attendanceOnline: bool,
      attendanceInPerson: bool
    }).isRequired
  }).isRequired
};

export default Certificate;
