const prize = {
  name: '',
  issuer: {
    name: ''
  },
  description: '',
  categoryId: '',
  jurisdictionId: ''
};

export default prize;
