import Grid from '@mui/material/Grid';
import { Dropdown, TextField } from '@paddl/storybook';
import {
  func,
  shape,
  string
} from 'prop-types';
import React, { useEffect, useState } from 'react';
import { updateValueByPathLookup } from '../../utils';

const UNITS_OPTIONS = [
  { text: 'Minutes', value: 'MINUTE' },
  { text: 'Hours', value: 'HOUR' }
];

const Article = ({
  cancelButton,
  continueButton,
  formSchema,
  data,
  data: {
    details: {
      title,
      provider,
      description,
      duration
    }
  }
}) => {
  const [newData, setNewData] = useState(data);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  const descriptionLength = newData.details.description?.length;
  const handleChange = (stringPath) => {
    const updatedData = updateValueByPathLookup(newData, stringPath);

    if (!isDirty) setIsDirty(true);
    if (JSON.stringify(newData) !== JSON.stringify(updatedData)) setNewData(updatedData);
  };

  useEffect(() => {
    if (isDirty && formSchema.isValidSync(newData)) setIsDisabled(false);
    if (isDirty && !formSchema.isValidSync(newData)) setIsDisabled(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, formSchema]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <TextField
          label="Article Title"
          placeholder="e.g Productivity in the Workplace"
          onChange={(item) => handleChange([
            { path: 'details.title', value: item }
          ])}
          defaultValue={title}
          isRequired
        />
        </Grid>
        <Grid item xs={12}>
        <TextField
          label="Author"
          placeholder="e.g Enid Blyton"
          onChange={(item) => handleChange([
            { path: 'details.provider.name', value: item },
            { path: 'details.provider.id', value: null }
          ])}
          defaultValue={provider.name}
          isRequired
        />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description (Optional)"
            data-test-id="description"
            placeholder="An exploration of new technology…"
            maxLength={1500}
            onChange={(item) => handleChange([
              { path: 'details.description', value: item }
            ])}
            defaultValue={description}
            multiline
            helperText={`Max 1500${descriptionLength > 0 ? `/${descriptionLength}` : ''} Characters`}
          />
        </Grid>
        <Grid item xs={12} md={3}>
        <TextField
          label="Read time"
          data-test-id="duration"
          placeholder="1"
          onChange={(item) => handleChange([
            { path: 'details.duration.value', value: parseInt(item, 10) }
          ])}
          defaultValue={duration.value ? duration.value.toString() : ''}
          maxLength={2}
          isRequired
        />
        </Grid>
        <Grid item xs={12} md={3}>
          <Dropdown
            defaultValue={duration.unit}
            data-test-id="unit"
            label="Unit"
            placeholder="Duration"
            onChange={(item) => handleChange([
              { path: 'details.duration.unit', value: item }
            ])}
            items={UNITS_OPTIONS}
            isRequired
          />
        </Grid>
      </Grid>
      <div className="footer">
        {cancelButton ? cancelButton() : <div />}
        {continueButton(newData, isDisabled)}
      </div>
    </>
  );
};

Article.propTypes = {
  cancelButton: func.isRequired,
  continueButton: func.isRequired,
  formSchema: shape({}).isRequired,
  data: shape({
    details: shape({
      title: string.isRequired,
      provider: shape({
        name: string
      }).isRequired,
      description: string,
      duration: shape({
        value: string,
        unit: string
      }).isRequired
    }).isRequired
  }).isRequired
};

export default Article;
