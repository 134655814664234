import * as Yup from 'yup';
import { atLeastOneOfBoolean } from '../../addMethods.yup';

atLeastOneOfBoolean();

const certificate = Yup.object().shape({
  details: Yup.object().shape({
    authority: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    certificate: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    certificateDuration: Yup.object().shape({
      unit: Yup.string().required(),
      value: Yup.number().required()
    }).defined(),
    location: Yup.string().required(),
    certificateType: Yup.string().required(),
    commitment: Yup.string().required(),
    attendanceOnline: Yup.boolean(),
    attendanceInPerson: Yup.boolean()
  }).atLeastOneOfBoolean([
    'attendanceOnline',
    'attendanceInPerson'
  ])
}).defined();

export default certificate;
