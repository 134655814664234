import React, { useState, useEffect } from 'react';
import {
  number,
  shape,
  string,
  func,
  arrayOf,
  oneOfType
} from 'prop-types';
import Grid from '@mui/material/Grid';
import { AutocompleteSelect, TextField, Dropdown } from '@paddl/storybook';
import {
  updateValueByPathLookup,
  getCountryList,
  handleSuggestionList,
  sanitizeSelectedValue
} from '../../utils';

const HighSchool = ({
  cancelButton,
  continueButton,
  formSchema,
  data,
  data: {
    details: {
      school = {},
      campus = {},
      subjects = [{}],
      location,
      grade
    }
  }
}) => {
  const [newData, setNewData] = useState(data);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [optionsSchool, setOptionsSchool] = useState([]);
  const [optionsCampuses, setOptionsCampuses] = useState([]);

  const handleChange = (stringPath) => {
    const updatedData = updateValueByPathLookup(newData, stringPath);

    if (!isDirty) setIsDirty(true);
    if (JSON.stringify(newData) !== JSON.stringify(updatedData)) setNewData(updatedData);
  };

  useEffect(() => {
    if (isDirty && formSchema.isValidSync(newData)) setIsDisabled(false);
    if (isDirty && !formSchema.isValidSync(newData)) setIsDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, newData, formSchema]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="School Name"
            data-test-id="school-name"
            placeholder="e.g Hometown High"
            defaultValue={school.name}
            options={optionsSchool}
            action={(item) => handleChange([
              { path: 'details.school.name', value: (item && sanitizeSelectedValue(item)) || null },
              { path: 'details.school.id', value: (item && item.value) || null }
            ])}
            getInputValue={async (value) => setOptionsSchool(await handleSuggestionList(value, 'education/schools'))}
            resetFieldAfterAction={false}
            allowCreateNewOption
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Campus Name"
            data-test-id="campus"
            placeholder="e.g Melbourne"
            defaultValue={campus.name}
            options={optionsCampuses}
            action={(item) => handleChange([
              { path: 'details.campus.name', value: (item && sanitizeSelectedValue(item)) || null },
              { path: 'details.campus.id', value: (item && item.value) || null }
            ])}
            getInputValue={async (value) => setOptionsCampuses(await handleSuggestionList(value, 'education/campuses'))}
            resetFieldAfterAction={false}
            allowCreateNewOption
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            data-test-id="country"
            defaultValue={location}
            label="Country"
            placeholder="Country"
            onChange={(item) => handleChange([
              { path: 'details.location', value: item }
            ])}
            items={getCountryList()}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Grade (Optional)"
            data-test-id="grade"
            placeholder="e.g 90.00"
            defaultValue={grade}
            onChange={(item) => handleChange([{ path: 'details.grade', value: item }])}
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Subjects"
            data-test-id="subjects"
            placeholder="e.g. English, History, etc."
            onChange={(item) => handleChange([
              {
                path: 'details.subjects',
                value: [{ name: item, id: null }]
              }
            ])}
            defaultValue={subjects.map((index) => index.name).join(', ')}
            maxLength={190}
            multiline
            helperText="Please separate each subject with a comma, max 190 Characters"
            isRequired
          />
        </Grid>
      </Grid>
      <div className="footer">
        {cancelButton ? cancelButton() : <div />}
        {continueButton(newData, isDisabled)}
      </div>
    </>
  );
};

HighSchool.propTypes = {
  cancelButton: func.isRequired,
  continueButton: func.isRequired,
  formSchema: shape({}).isRequired,
  data: shape({
    details: shape({
      school: shape({
        id: number,
        name: string
      }),
      campus: shape({
        id: number,
        name: string
      }),
      subjects: arrayOf(
        shape({
          id: oneOfType([number, string]),
          name: string
        })
      ),
      grade: string,
      location: string
    }).isRequired
  }).isRequired
};

export default HighSchool;
