import React, { useState, useEffect } from 'react';
import {
  number,
  shape,
  string,
  bool,
  oneOf,
  func,
  oneOfType
} from 'prop-types';
import {
  initGoogleLocationApi,
  fetchLocationSuggestions,
  responsibilityUnionTypes,
  levelUnionTypes,
  levelOptionList,
  primaryIndustryOptionList,
  responsibilityOptionList
} from '@paddl/utils-js';
import Grid from '@mui/material/Grid';
import {
  TextField,
  Dropdown,
  Checkbox,
  AutocompleteSelect,
  useWidth
} from '@paddl/storybook';
import {
  updateValueByPathLookup,
  handleSuggestionList,
  sanitizeSelectedValue
} from '../../utils';

export const Contract = ({
  cancelButton,
  continueButton,
  formSchema,
  data,
  data: {
    details: {
      role = {},
      employer = {},
      workingLocation = {},
      primaryIndustryId,
      hoursPerWeek,
      level,
      responsibility,
      description,
      attendanceInHouse,
      attendanceRemote
    }
  }
}) => {
  const width = useWidth();
  const [newData, setNewData] = useState(data);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [optionsRole, setOptionsRole] = useState([]);
  const [optionsEmployer, setOptionsEmployer] = useState([]);

  const descriptionLength = newData.details.description.length;

  const handleChange = (stringPath) => {
    const updatedData = updateValueByPathLookup(newData, stringPath);

    if (!isDirty) setIsDirty(true);
    if (JSON.stringify(newData) !== JSON.stringify(updatedData)) setNewData(updatedData);
  };

  const handleLocations = (address = '') => {
    fetchLocationSuggestions(address, (locations) => {
      if (currentAddress !== address) {
        setCurrentAddress(address);
        setLocationSuggestions(locations);
      }
    });
  };

  useEffect(() => {
    if (isDirty && formSchema.isValidSync(newData)) setIsDisabled(false);
    if (isDirty && !formSchema.isValidSync(newData)) setIsDisabled(true);
    if (typeof window.google === 'undefined') initGoogleLocationApi();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, formSchema]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Role / Title"
            data-test-id="role"
            placeholder="e.g Freelance Designerr"
            defaultValue={role.name}
            options={optionsRole}
            action={(item) => handleChange([
              { path: 'details.role.name', value: (item && sanitizeSelectedValue(item)) || null },
              { path: 'details.role.id', value: (item && item.value) || null }
            ])}
            resetFieldAfterAction={false}
            getInputValue={async (value) => {
              setOptionsRole(await handleSuggestionList(value, 'employment/contract/roles'));
            }}
            allowCreateNewOption
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Employer Name"
            data-test-id="employer-name"
            placeholder="e.g ABCD Design Studio"
            defaultValue={employer.name}
            options={optionsEmployer}
            action={(item) => handleChange([
              {
                path: 'details.employer',
                value: {
                  name: (item && (item.title.includes('Use') ? sanitizeSelectedValue(item) : item.title)) || null,
                  id: (item && item.value) || null,
                  profileId: null,
                  icon: null,
                  location: {}
                }
              }
            ])}
            getInputValue={async (value) => setOptionsEmployer(await handleSuggestionList(value, 'employment/employers'))}
            resetFieldAfterAction={false}
            allowCreateNewOption
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label={width === 'md' ? 'Commitment hrs/wk' : 'Commitment (Hours per Week)'}
            data-test-id="commitment"
            placeholder="40"
            onChange={(item) => handleChange([{ path: 'details.hoursPerWeek', value: item }])}
            defaultValue={hoursPerWeek ? hoursPerWeek.toString() : ''}
            maxLength={2}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={primaryIndustryId}
            label="Primary Industry"
            data-test-id="primary-industry"
            placeholder="Accounting"
            onChange={(item) => handleChange([
              { path: 'details.primaryIndustryId', value: item }
            ])}
            items={primaryIndustryOptionList()}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={level}
            label="Level"
            data-test-id="level"
            placeholder="Senior"
            onChange={(item) => handleChange([
              { path: 'details.level', value: item }
            ])}
            items={levelOptionList}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={responsibility}
            label="Responsibility"
            data-test-id="responsibility"
            placeholder="Manager"
            onChange={(item) => handleChange([
              { path: 'details.responsibility', value: item }
            ])}
            items={responsibilityOptionList}
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Working Location"
            action={(item) => {
              const location = item || '';
              handleChange([{
                path: 'details.workingLocation',
                value: {
                  placeId: location.value,
                  originalText: location.title,
                  shortText: location.shortText,
                  lat: location.lat,
                  lng: location.lng
                }
              }]);
            }}
            defaultValue={workingLocation.originalText}
            options={locationSuggestions}
            getInputValue={(value) => handleLocations(value)}
            isRequired
          />
        </Grid>
        <Grid item xs={12} className="checkbox-label">Attendance *</Grid>
        <Grid item xs={6}>
          <Checkbox
            defaultChecked={attendanceInHouse}
            label="In-House"
            data-test-id="in-house"
            value={0}
            onChange={(isChecked) => handleChange([
              { path: 'details.attendanceInHouse', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={6}>
          <Checkbox
            defaultChecked={attendanceRemote}
            label="Remote"
            data-test-id="remote"
            value={1}
            onChange={(isChecked) => handleChange([
              { path: 'details.attendanceRemote', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description (Optional)"
            data-test-id="description"
            placeholder="Share more about your day-to-day role and key responsibilities..."
            maxLength={1500}
            onChange={(item) => handleChange([
              { path: 'details.description', value: item }
            ])}
            defaultValue={description}
            multiline
            isOutlined
            helperText={`Max 1500${descriptionLength > 0 ? `/${descriptionLength}` : ''} Characters`}
          />
        </Grid>
      </Grid>
      <div className="footer">
        {cancelButton ? cancelButton() : <div />}
        {continueButton(newData, isDisabled)}
      </div>
    </>
  );
};

Contract.propTypes = {
  cancelButton: func.isRequired,
  continueButton: func.isRequired,
  formSchema: shape({}).isRequired,
  data: shape({
    details: shape({
      role: shape({
        id: number,
        name: string
      }),
      employer: shape({
        id: number,
        name: string
      }),
      workingLocation: shape({
        originalText: string
      }),
      primaryIndustryId: oneOfType([number, string]),
      hoursPerWeek: oneOfType([number, string]),
      level: oneOf([...levelUnionTypes, '']),
      responsibility: oneOf([...responsibilityUnionTypes, '']),
      description: string,
      attendanceInHouse: bool,
      attendanceRemote: bool
    }).isRequired
  }).isRequired
};

export default Contract;
