import React, { useEffect, useState } from 'react';
import { string, shape, func } from 'prop-types';
import Grid from '@mui/material/Grid';
import {
  badgesMetadata,
  ActivityCard,
  ProductPortalCta,
  PageHeader,
  MainContentWrapper,
  InnerContentWrapper,
  PageColumn,
  ModularPage
} from '@paddl/storybook';
import {
  getActivitiesByCategoryName,
  getVariantIdByActivityTitle
} from '@paddl/utils-js';
import { Metadata, MemberAppNavigation_Populated } from '@paddl/utils-react';
import { CardVoteWrapper } from './NewActivityCategory.styles';

export const _NewActivityCategory = ({
  categoryName,
  history
}) => {
  const [activities, setActivities] = useState([]);

  const goToForm = (variantId) => history.push(`${categoryName}/${variantId}`);

  const renderCard = (itemType) => {
    const { category } = badgesMetadata[itemType];
    const { activities: activitiesListInPlay, title: activityTitle } = activities;

    if (category === activityTitle && itemType) {
      const isActivityEnabled = activitiesListInPlay.find(({ title }) => title === itemType);
      const variantId = isActivityEnabled && getVariantIdByActivityTitle(itemType);

      return (
        <Grid
          item
          xs={6}
          md={4}
          key={itemType}
        >
          <ActivityCard
            data-test-id="activity-card"
            type={itemType}
            onClick={() => goToForm(variantId)}
            isDisabled={!isActivityEnabled}
          />
        </Grid>
      );
    }

    return null;
  };

  useEffect(() => {
    try {
      const activityInPlay = getActivitiesByCategoryName(categoryName);

      setActivities(activityInPlay);
    } catch (error) {
      window.location.href = '/not-found';
    }
  }, [categoryName]);

  useEffect(() => window.scrollTo(0, 0), []);

  const { title, description } = activities;

  return (
    <MainContentWrapper colorVariant="dark" withGhostNav>
      <Metadata title={`Create ${title} Activity | Paddl`} />
      <InnerContentWrapper size="small">
      <PageColumn>
      <PageHeader heading={`Create a new ${title} Activity`} tagline={description} />
        <Grid
          container
          spacing={2}
        >
          {Object.keys(badgesMetadata).map((itemType) => renderCard(itemType))}
        </Grid>
        <CardVoteWrapper>
          <ProductPortalCta
            url="https://portal.productboard.com/xw87v7jxrrjqoxozbcd8awmx/tabs/2-under-consideration"
          />
        </CardVoteWrapper>
      </PageColumn>
      </InnerContentWrapper>
    </MainContentWrapper>
  );
};

_NewActivityCategory.propTypes = {
  categoryName: string.isRequired,
  history: shape({
    push: func.isRequired
  }).isRequired
};

export const NewActivityCategory = ({
  history,  match: {
    params: {
      category: categoryName
    }
  }
}) => {
  const breadcrumbItems = [
    {
      title: 'Add Activity',
      action: () => history.push('/activities/new')
    },
    {
      title: categoryName
    }
  ];

  return (
<ModularPage
  pageContent={<_NewActivityCategory history={history} categoryName={categoryName} />}
  navigationContent={<MemberAppNavigation_Populated currentPage="activity" />}
  breadcrumbItems={breadcrumbItems}
/>);
};

NewActivityCategory.propTypes = {
  match: shape({
    params: shape({
      category: string.isRequired
    }).isRequired
  }).isRequired,
  history: shape({
    push: func.isRequired
  }).isRequired
};
