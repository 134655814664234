const masterDegree = {
  educator: {
    name: ''
  },
  campus: {
    name: ''
  },
  course: {
    name: ''
  },
  courseDuration: '',
  commitment: '',
  attendanceOnline: false,
  attendanceOnCampus: false
};

export default masterDegree;
