const certificate = {
  authority: {
    name: ''
  },
  certificate: {
    name: ''
  },
  certificateDuration: {
    unit: '',
    value: ''
  },
  location: '',
  certificateType: '',
  commitment: '',
  attendanceOnline: false,
  attendanceInPerson: false
};

export default certificate;
