import React, { useState, useEffect } from 'react';
import {
  func,
  shape,
  arrayOf,
  number,
  string
} from 'prop-types';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import { Chip, AutocompleteSelect } from '@paddl/storybook';
import {
  SkillsEditWrapper,
  SkillsNumberTitle,
  SuggestionsWrapper,
  SubHealine
} from '../Activity.styles';

export const SkillsEdit = ({
  cancelButton,
  continueButton,
  formSchema,
  data,
  skillsSuggestion
}) => {
  const [newData, setNewData] = useState(data);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [options, setOptions] = useState([]);
  const [currentValue, setCurrentValue] = useState(null);

  const handleDelete = (index) => {
    const updatedData = JSON.parse(JSON.stringify(newData));

    updatedData.skillset = [
      ...newData.skillset.slice(0, index),
      ...newData.skillset.slice(index + 1)
    ];

    setIsDirty(true);
    setNewData(updatedData);
  };

  const isSkillAlreadySelected = (skillName) => {
    if (skillName && newData.skillset) {
      return newData.skillset.some((skill) => skill.name === skillName);
    }

    return false;
  };

  const handleSelection = (skill) => {
    if (skill && skill.title && !isSkillAlreadySelected(skill.title)) {
      const { value, title } = skill;
      const updatedData = JSON.parse(JSON.stringify(newData));
      const id = value || null;
      const name = id === null ? (title.split(' ').slice(1).join(' ')) : title;
      const newVal = { id, name };

      updatedData.skillset.push(newVal);

      setIsDirty(true);
      setNewData(updatedData);
    }
  };

  const handleSelectSuggestedSkill = (skillIndex) => {
    const updatedData = JSON.parse(JSON.stringify(newData));
    const skillName = skillsSuggestion[skillIndex];

    updatedData.skillset.push({
      id: null,
      name: skillName
    });
    setIsDirty(true);
    setNewData(updatedData);
  };

  const handleSuggestionList = async (value = '') => {
    if (currentValue !== value) {
      try {
        const endpoint = `${process.env.API_PREFIX_V2}/skills/suggestions?substring=${value}`;
        const res = await axios.get(endpoint);
        const newVal = res.data.map((item) => ({
          value: item.id,
          title: item.name
        }));

        setCurrentValue(value);
        setOptions(newVal);
      } catch (err) {
        // eslint-disable-next-line
        console.error(err);
      }
    }
  };

  const renderSuggestions = () =>
    skillsSuggestion.map((item, index) =>
      <Chip
        key={item}
        label={item}
        id={index}
        actionType="clickable"
        action={() => handleSelectSuggestedSkill(index)}
        isDisabled={newData?.skillset?.filter(
          ({ name }) => Boolean(item.trim().toLowerCase() === name.trim().toLowerCase())
        ).length > 0}
      />);

  const renderChips = () =>
    newData &&
    newData.skillset &&
    newData.skillset.map(({ name, id }, index) =>
      <Chip
        label={name}
        action={() => handleDelete(index)}
        id={id}
        // eslint-disable-next-line react/no-array-index-key
        key={`${id}-${index}`}
      />);

  useEffect(() => {
    if (isDirty && formSchema.isValidSync(newData)) setIsDisabled(false);
    if (isDirty && !formSchema.isValidSync(newData)) setIsDisabled(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, formSchema]);

  return (
    <SkillsEditWrapper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Enter a Skill Name"
            action={(value) => handleSelection(value)}
            defaultValue={null}
            options={options}
            getInputValue={(value) => handleSuggestionList(value)}
            allowCreateNewOption
            resetFieldAfterAction
          />
        </Grid>
      </Grid>
      {skillsSuggestion.length
        ? <SuggestionsWrapper>
            <Grid container spacing={2}>
              <Grid item md={2} xs={12}>
                <SubHealine>
                  Suggestions:
                </SubHealine>
              </Grid>
              <Grid
                item
                md={10}
                xs={12}
                data-test-id="skills-suggestion"
              >
                {renderSuggestions()}
              </Grid>
            </Grid>
          </SuggestionsWrapper>
        : null}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SkillsNumberTitle>
            {(newData && newData.skillset) ? newData.skillset.length : 0} Skills Selected
          </SkillsNumberTitle>
          {renderChips()}
        </Grid>
      </Grid>
      <div className="footer">
        {cancelButton ? cancelButton() : <div />}
        {continueButton(newData, isDisabled, 'skills')}
      </div>
    </SkillsEditWrapper>
  );
};

SkillsEdit.propTypes = {
  cancelButton: func,
  continueButton: func.isRequired,
  formSchema: shape({}).isRequired,
  data: shape({
    skillset: arrayOf(
      shape({
        id: number,
        name: string
      }).isRequired
    ).isRequired
  }).isRequired,
  skillsSuggestion: arrayOf(string)
};

SkillsEdit.defaultProps = {
  cancelButton: null,
  skillsSuggestion: []
};
