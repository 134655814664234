import React from 'react';
import {
  bool,
  string,
  func
} from 'prop-types';
import { TextField, Icons } from '@paddl/storybook';
import { humanisedErrorMessage } from '../../pages/Activity/utils';
import { BookmarkTitleWrapper } from './ActivityMediaForm.styles';

const ShareLinkIcon = Icons.shareLink;

export const ActivityMediaForm = ({
  handlePressEnter,
  activityName,
  setUrl,
  hasError,
  errorText
}) =>
  <div
    role="textbox"
    tabIndex={0}
    onKeyDown={handlePressEnter}
  >
    <BookmarkTitleWrapper>
      <ShareLinkIcon />
      <span>Add a link to your</span> {activityName}
    </BookmarkTitleWrapper>
    <TextField
      data-test-id="textfield"
      placeholder="https://"
      onChange={(text) => setUrl(text)}
      hasError={hasError}
      errorText={humanisedErrorMessage(errorText)}
      helperText="Paste your link and hit enter to fetch a preview."
    />
  </div>;

ActivityMediaForm.propTypes = {
  handlePressEnter: func.isRequired,
  activityName: string.isRequired,
  setUrl: func.isRequired,
  hasError: bool,
  errorText: string
};

ActivityMediaForm.defaultProps = {
  hasError: false,
  errorText: ''
};
