import React, { useState, useEffect } from 'react';
import {
  number,
  shape,
  string,
  bool,
  oneOf,
  func,
  oneOfType
} from 'prop-types';
import {
  commitmentOptionList,
  getActivityDurationNumberOptionList,
  commitmentUnionTypes,
  activityDurationUnitOptionList
} from '@paddl/utils-js';
import Grid from '@mui/material/Grid';
import { AutocompleteSelect, Dropdown, Checkbox } from '@paddl/storybook';
import { updateValueByPathLookup, handleSuggestionList, sanitizeSelectedValue } from '../../utils';

const Course = ({
  cancelButton,
  continueButton,
  formSchema,
  data,
  data: {
    details: {
      trainer = {},
      course = {},
      courseDuration = {},
      commitment,
      attendanceOnline,
      attendanceInPerson
    }
  }
}) => {
  const [newData, setNewData] = useState(data);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [optionsCourse, setOptionsCourse] = useState([]);
  const [optionsEducator, setOptionsEducator] = useState([]);

  const handleChange = (stringPath) => {
    const updatedData = updateValueByPathLookup(newData, stringPath);

    if (!isDirty) setIsDirty(true);
    if (JSON.stringify(newData) !== JSON.stringify(updatedData)) setNewData(updatedData);
  };

  useEffect(() => {
    if (isDirty && formSchema.isValidSync(newData)) setIsDisabled(false);
    if (isDirty && !formSchema.isValidSync(newData)) setIsDisabled(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, formSchema]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Trainer"
            data-test-id="trainer"
            placeholder="e.g Code Academy"
            defaultValue={trainer.name}
            options={optionsEducator}
            action={(item) => handleChange([
              { path: 'details.trainer.name', value: (item && sanitizeSelectedValue(item)) || null },
              { path: 'details.trainer.id', value: (item && item.value) || null }
            ])}
            resetFieldAfterAction={false}
            getInputValue={async (value) => setOptionsEducator(await handleSuggestionList(value, 'education/trainers'))}
            allowCreateNewOption
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSelect
            label="Course Name"
            data-test-id="course-name"
            placeholder="e.g Web Development"
            defaultValue={course.name}
            options={optionsCourse}
            action={(item) => handleChange([
              { path: 'details.course.name', value: (item && sanitizeSelectedValue(item)) || null },
              { path: 'details.course.id', value: (item && item.value) || null }
            ])}
            resetFieldAfterAction={false}
            getInputValue={async (value) => {
              setOptionsCourse(await handleSuggestionList(value, 'education/variants/course/courses'));
            }}
            allowCreateNewOption
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Dropdown
            defaultValue={courseDuration.value}
            data-test-id="duration"
            label="Duration"
            placeholder="Duration"
            onChange={(item) => handleChange([
              { path: 'details.courseDuration.value', value: item }
            ])}
            items={getActivityDurationNumberOptionList()}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Dropdown
            defaultValue={courseDuration.unit}
            data-test-id="unit"
            label="Unit"
            placeholder="Duration"
            onChange={(item) => handleChange([
              { path: 'details.courseDuration.unit', value: item }
            ])}
            items={activityDurationUnitOptionList}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={commitment}
            data-test-id="commitment"
            label="Commitment"
            placeholder="Commitment"
            onChange={(item) => handleChange([
              { path: 'details.commitment', value: item }
            ])}
            items={commitmentOptionList}
            isRequired
          />
        </Grid>
        <Grid item xs={12} className="checkbox-label">Attendance *</Grid>
        <Grid item xs={6}>
          <Checkbox
            data-test-id="in-person"
            defaultChecked={attendanceInPerson}
            label="In Person"
            value={0}
            onChange={(isChecked) => handleChange([
              { path: 'details.attendanceInPerson', value: isChecked }
            ])}
          />
        </Grid>
        <Grid item xs={6}>
          <Checkbox
            data-test-id="online"
            defaultChecked={attendanceOnline}
            label="Online"
            value={1}
            onChange={(isChecked) => handleChange([
              { path: 'details.attendanceOnline', value: isChecked }
            ])}
          />
        </Grid>
      </Grid>
      <div className="footer">
        {cancelButton ? cancelButton() : <div />}
        {continueButton(newData, isDisabled)}
      </div>
    </>
  );
};

Course.propTypes = {
  cancelButton: func.isRequired,
  continueButton: func.isRequired,
  formSchema: shape({}).isRequired,
  data: shape({
    details: shape({
      trainer: shape({
        id: number,
        name: string
      }),
      course: shape({
        id: number,
        name: string
      }),
      courseDuration: shape({
        value: oneOfType([number, string]),
        unit: string
      }),
      commitment: oneOf([...commitmentUnionTypes, '']),
      attendanceOnline: bool,
      attendanceInPerson: bool
    }).isRequired
  }).isRequired
};

export default Course;
