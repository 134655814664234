import React, { useEffect, useRef } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Switch, Route } from 'react-router-dom';
import { routerConfig } from './routerConfig';

export const CustomRoute = (route) => {
  const { path, exact, component } = route;

  return (
    <Route
      path={path}
      exact={exact}
      prefix=""
      component={component}
      key="fixed"
    />
  );
};

export const RouterWrapper = (location) => {
  // Recording the async page view event into Segment
  // Note: recording the very first async page view as Segment natively records page refresh and would result in a double count
  const hasUpdated = useRef(false);

  useEffect(() => {
    // Break out on very first update
    if (!hasUpdated.current) {
      hasUpdated.current = true;

      return;
    }
    // Record a new page view
    // eslint-disable-next-line
    if (window.analytics) window.analytics.page(location.pathname);
  }, [location]);

  // Mapping routes object into react-router JSX format
  const getRoutes = () => {
    const res = [];
    routerConfig.map((route) => res.push(CustomRoute(route)));

    return res;
  };

  // Rendering Routes
  const routes = (
    <Switch location={location}>
      {getRoutes(routerConfig)}
    </Switch>
  );

  return routes;
};

export const Router = withAuthenticationRequired(RouterWrapper);
