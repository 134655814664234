/* eslint-disable import/extensions */
import { number } from 'prop-types';
import {
  getActivityTitleByVariantId,
  getCapitalizedComponentName
} from '@paddl/utils-js';
import * as defaultStates from './defaultStates';

// Apparently this file has to be modified for changes to be picked up.
// Bumped: Thur Nov 9 2022 17:07:40 GMT+1000 (Australian Eastern Standard Time)
export const defaultStatesFactory = (activityId) => {
  if (!activityId) throw new Error('activityId argument cannot be undefined');

  const componentName = getActivityTitleByVariantId(activityId);
  const capitalizedName = getCapitalizedComponentName(componentName);

  if (!defaultStates[capitalizedName]) {
    throw new Error(`The "${capitalizedName}.js" file cannot be found inside the DetailsSection/defaultStates/ folder`);
  }

  return { defaultFormStates: defaultStates[capitalizedName] };
};

defaultStatesFactory.propTypes = {
  activityId: number.isRequired
};
