const paddlGames = {
  competitionPlace: null,
  eventId: null,
  roleCreative: false,
  roleDemonstrator: false,
  rolePresenter: false,
  roleTeamLeader: false,
  teamName: ''
};

export default paddlGames;
