import {
  Activity,
  NewActivity,
  NewActivityCategory,
  NewActivityName
} from '../pages';

export const routerConfig = [
  {
    path: '/activities/new/:category/:variantId',
    component: NewActivityName
  },
  {
    path: '/activities/new/:category',
    component: NewActivityCategory
  },
  {
    path: '/activities/new',
    component: NewActivity
  },
  {
    path: '/activities/:activityId',
    component: Activity
  }
];
