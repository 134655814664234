import React, { useState } from 'react';
import { func } from 'prop-types';
import { Global } from '@emotion/react';
import { IconButton, ConfirmationModal } from '@paddl/storybook';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  ShowMoreIconWrapper,
  menuWrapperGlobal,
  RemoveText
} from './DeleteActivity.styles';

export const DeleteActivity = ({ action }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleCloseModal = () => {
    setIsConfirmationModalOpen(false);
    handleClose();
  };

  return (
    <>
      <ShowMoreIconWrapper>
        <IconButton
          onClick={handleClick}
          size="small"
          color="grey"
          icon="navMore"
          variant="plained"
        />
      </ShowMoreIconWrapper>
      <Global styles={menuWrapperGlobal} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transitionDuration={250}
        autoFocus={false}
        classes={{ paper: 'custom-paper', list: 'custom-list' }}
      >
        <MenuItem
          disableRipple
          onClick={() => setIsConfirmationModalOpen(true)}
          classes={{ gutters: 'custom-button' }}
        >
          <RemoveText>Remove Activity</RemoveText>
        </MenuItem>
      </Menu>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        handleExit={() => handleCloseModal()}
        confirmButtonCallback={() => {
          handleCloseModal();
          action();
        }}
        contentText="Removing an Activity cannot be undone."
      />
    </>
  );
};

DeleteActivity.propTypes = {
  action: func.isRequired
};
