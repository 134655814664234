import React from 'react';

export const ActivityFormContext = React.createContext({
  data: {},
  setData: () => {},
  isDirty: {
    details: false,
    skills: false,
    progress: false
  },
  setIsDirty: () => {},
  edit: '', // details, progess, skills
  setEdit: () => {},
  variantId: 0,
  sectionCompletedCount: 0
});
