import styled from '@emotion/styled';
import { Colors } from '@paddl/storybook';

const { grey_lighter, grey_athens } = Colors;

export const NewActivityNameSections = styled.div`
  > section {

    &.viewable {
      background: ${grey_athens};
    }
  }
`;

export const ActivityStepIndicatorSummaryWrapper = styled.div`
  margin-top: 36px;
  padding: 16px;
  border-top: 1px solid ${grey_lighter};
  border-bottom: 1px solid ${grey_lighter};
`;

export const ActivityStepIndicatorDetailsPanelContainer = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${grey_lighter};
`;

export const SubmitButtonWrapper = styled.div`
  text-align: center;
  padding-top: 20px;
`;

export const EditWrapper = styled.div`
  padding: 32px 60px;
`;
