import React, { useState, useEffect } from 'react';
import { Datepicker, Slider, Checkbox } from '@paddl/storybook';
import Grid from '@mui/material/Grid';
import {
  func,
  shape,
  number,
  oneOf,
  bool,
  string
} from 'prop-types';
import { progressUnionTypes } from '@paddl/utils-js';
import { updateValueByPathLookup } from '../utils';

export const MAX_END_DATE = '01/01/2100';

const getInitialEndDate = (endDate, currentDate) => {
  const { day, month, year } = endDate;
  const formattedCurrentDate = currentDate.toISOString().split('T')[0].split('-').reverse().join('/');

  return (day && month && year) ? `${day}/${month}/${year}` : formattedCurrentDate;
};

export const ProgressEdit = ({
  cancelButton,
  continueButton,
  formSchema,
  progressType,
  isProgressDisabled,
  data,
  startDateLabel,
  data: {
    duration: {
      startDate: {
        day: startDay,
        month: startMonth,
        year: startYear
      } = {},
      endDate = {},
      timeStamp: {
        day: timeStampDay,
        month: timeStampMonth,
        year: timeStampYear
      } = {}
    } = {}
  }
}) => {
  const [newData, setNewData] = useState(data);
  const [isEndDateFuture, setIsEndDateFuture] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  const handleChange = (stringPath) => {
    const updatedData = updateValueByPathLookup(newData, stringPath);
    setIsDirty(true);
    if (JSON.stringify(newData) !== JSON.stringify(updatedData)) setNewData(updatedData);
  };

  const helpText = progressType === 'INDEFINITE'
    ? 'Select ‘Current Role’ if this Activity is ongoing.'
    : 'Date finished or expected to finish.';

  const { duration: { isCurrent } = {} } = newData;

  const currentDate = new Date();

  const handleChangeEndDate = (formattedEndDate) => {
    handleChange([
      { path: 'duration.endDate.day', value: isCurrent ? null : Number(formattedEndDate.split('/')[0]) },
      { path: 'duration.endDate.month', value: isCurrent ? null : Number(formattedEndDate.split('/')[1]) },
      { path: 'duration.endDate.year', value: isCurrent ? null : Number(formattedEndDate.split('/')[2]) }
    ]);
  };

  useEffect(() => {
    if (!isProgressDisabled) {
      if (isDirty && formSchema.isValidSync(newData)) setIsDisabled(false);
      if (isDirty && !formSchema.isValidSync(newData)) setIsDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, formSchema]);

  useEffect(() => {
    const nowDate = new Date(Date.now());
    const { month, day, year } = newData?.duration?.endDate || { month: null, year: null, day: null };
    const newEndDate = (day && month && year) ? new Date(`${month}/${day}/${year}`) : new Date();

    setIsEndDateFuture(newEndDate.getTime() > nowDate.getTime());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);

  useEffect(() => {
    if (isEndDateFuture) {
      handleChange([{ path: 'percentageComplete', value: null }]);
    }

    if (!newData.percentageComplete && !isEndDateFuture) {
      handleChange([{ path: 'percentageComplete', value: 100 }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEndDateFuture, newData.percentageComplete, newData.duration.endDate, isCurrent]);

  useEffect(() => {
    if (!isCurrent) {
      const date = getInitialEndDate(endDate, currentDate);
      handleChangeEndDate(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrent]);

  const getMaxEndDate = () => {
    if (progressType === 'INDEFINITE') {
      return isCurrent ? MAX_END_DATE : currentDate;
    }

    return undefined;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Datepicker
            action={(item) => handleChange([
              { path: progressType !== 'TIME_STAMP' ? 'duration.startDate.day' : 'duration.timeStamp.day', value: Number(item.split('/')[0]) },
              { path: progressType !== 'TIME_STAMP' ? 'duration.startDate.month' : 'duration.timeStamp.month', value: Number(item.split('/')[1]) },
              { path: progressType !== 'TIME_STAMP' ? 'duration.startDate.year' : 'duration.timeStamp.year', value: Number(item.split('/')[2]) }
            ])}
            label={startDateLabel}
            data-test-id="start-date-picker"
            defaultValue={progressType !== 'TIME_STAMP' ? `${startDay}/${startMonth}/${startYear}` : `${timeStampDay}/${timeStampMonth}/${timeStampYear}`}
            isDisabled={isProgressDisabled}
            maxDate={
              progressType === 'INDEFINITE'
                ? currentDate
                : undefined
            }
          />
        </Grid>
        {progressType !== 'TIME_STAMP' &&
          <Grid item xs={6}>
            <Datepicker
              action={(item) => handleChangeEndDate(item)}
              label="End"
              data-test-id="end-date-picker"
              defaultValue={isCurrent ? MAX_END_DATE : getInitialEndDate(endDate, currentDate)}
              isDisabled={isCurrent || isProgressDisabled}
              helperText={helpText}
              maxDate={getMaxEndDate()}
            />
          </Grid>}
        {!isProgressDisabled &&
          progressType === 'TIME_BOX' && !isEndDateFuture && newData.percentageComplete &&
          <Grid item xs={12}>
            <Slider
              action={(item) => handleChange(
                [{ path: 'percentageComplete', value: item }]
              )}
              defaultValue={newData.percentageComplete || 100}
              helperText="You can edit % Complete once an Activity has finished."
              hasPercentage
            />
          </Grid>}
        {!isProgressDisabled &&
          progressType === 'INDEFINITE' &&
          <>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Checkbox
                defaultChecked={isCurrent}
                label="Current Role"
                value={1}
                onChange={(isChecked) => handleChange(
                  [{ path: 'duration.isCurrent', value: isChecked }]
                )}
              />
            </Grid>
          </>}
      </Grid>
      <div className="footer">
        {cancelButton ? cancelButton() : <div />}
        {continueButton(newData, isDisabled, 'progress')}
      </div>
    </>
  );
};

ProgressEdit.propTypes = {
  cancelButton: func,
  continueButton: func.isRequired,
  formSchema: shape({}).isRequired,
  progressType: oneOf(progressUnionTypes).isRequired,
  isProgressDisabled: bool.isRequired,
  startDateLabel: string,
  data: shape({
    percentageComplete: number,
    duration: shape({
      startDate: shape({
        day: number,
        month: number,
        year: number
      }),
      endDate: shape({
        day: number,
        month: number,
        year: number
      }),
      timeStamp: shape({
        day: number,
        month: number,
        year: number
      }),
      isCurrent: bool
    }).isRequired
  }).isRequired
};

ProgressEdit.defaultProps = {
  cancelButton: null,
  startDateLabel: 'Start'
};
