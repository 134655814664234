import * as Yup from 'yup';

export const atLeastOneOfBoolean = () => {
  /* eslint-disable no-template-curly-in-string, func-names */
  Yup.addMethod(Yup.object, 'atLeastOneOfBoolean', function (list) {
    return this.test({
      name: 'atLeastOneOf',
      message: '${path} must have at least one: ${keys}',
      exclusive: true,
      params: { keys: list.join(', ') },
      test: (value) => value === undefined || list.some((f) => value[f] !== false)
    });
  });
};
