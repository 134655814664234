import {
  InAppGhostNav,
  OpenSidebarButton,
  SkillsRead,
  badgesMetadata,
  MainContentWrapper,
  InnerContentWrapper,
  PageColumn
} from '@paddl/storybook';
import {
  getActivityProgressTypeByVariantId,
  getActivityTitleByVariantId,
  getCategoryNameByVariantId,
  hardRedirect
} from '@paddl/utils-js';
import {
  Metadata
} from '@paddl/utils-react';
import { shape } from 'prop-types';
import React from 'react';
import {
  ActivityWrapper
} from './Activity.styles';
import { detailsSectionFactory } from './DetailsSection/detailsSectionFactory';
import { EditableSectionWrapper } from './EditableSectionWrapper';
import DefaultMedia from './MediaSection/read/DefaultMedia';
import PaddlGames from './MediaSection/read/PaddlGames';
import { ProgressRead } from './ProgressSection';

export const ViewOthersActivity = ({
  activity: data,
  activity: {
    variantId
  }
}) => {
  const activityTitle = variantId && getActivityTitleByVariantId(variantId);
  const activityName = activityTitle && badgesMetadata[activityTitle]?.name;
  const categoryName = getCategoryNameByVariantId(variantId || 1);
  const contenfulPageTitle = data?.contentfulFields?.title || '';
  const pageTitle = `${activityName || ''}${contenfulPageTitle && ' - '}${contenfulPageTitle}`;

  const renderSections = () => {
    const { DetailsRead } = detailsSectionFactory(variantId);
    const progressType = getActivityProgressTypeByVariantId(variantId);

    return [<DetailsRead
      data={data}
    />, <ProgressRead
      data={data}
      progressType={progressType}
    />, <SkillsRead
      data={data}
    />].map((item) => (
            <section>
        <EditableSectionWrapper canEdit={false}>
              {item}
        </EditableSectionWrapper>
            </section>
    ));
  };

  // variantId '4' => paddlGames
  const renderMediaSection = () => {
    return variantId.toString() === '4'
      ? <PaddlGames
          data={data}
          activityName={activityName}
          isViewingOwnActivity={false}
      />
      : <DefaultMedia
          data={data}
          activityName={activityName}
          categoryName={categoryName}
          isViewingOwnActivity={false}
      />;
  };

  const breadcrumbItems = [
    {
      title: 'Activities',
      action: () => hardRedirect('profileById', { id: data.metadata.authorId })
    },
    { title: pageTitle }
  ];

  return (
    <>
      <InAppGhostNav breadcrumbItems={breadcrumbItems} pageActionItems={[<OpenSidebarButton />]} />
      <Metadata title={`${pageTitle} | Paddl`} />
      <MainContentWrapper>
        <PageColumn>
            {renderMediaSection()}
            <InnerContentWrapper size="small">
              <ActivityWrapper>
                {renderSections()}
              </ActivityWrapper>
            </InnerContentWrapper>
        </PageColumn>
      </MainContentWrapper>
    </>
  );
};

ViewOthersActivity.propTypes = {
  activity: shape({}).isRequired
};
