const contract = {
  role: {
    name: ''
  },
  employer: {
    name: ''
  },
  workingLocation: {
    originalText: '',
    shortText: ''
  },
  primaryIndustryId: '',
  hoursPerWeek: '',
  level: '',
  responsibility: '',
  description: '',
  attendanceInHouse: false,
  attendanceRemote: false
};

export default contract;
