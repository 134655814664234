import * as Yup from 'yup';

const sideHustle = Yup.object().shape({
  details: Yup.object().shape({
    role: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    employer: Yup.object().shape({
      name: Yup.string().required()
    }).defined(),
    workingLocation: Yup.object().shape({
      originalText: Yup.string().required(),
      shortText: Yup.string()
    }).defined(),
    primaryIndustryId: Yup.number().required(),
    hoursPerWeek: Yup.number().required()
      .test('range', 'Total should range between 1 and 99', (value) => value >= 1 && value <= 99),
    description: Yup.string().nullable(),
    motivation: Yup.object().shape({
      passion: Yup.boolean().required(),
      income: Yup.boolean().required(),
      learning: Yup.boolean().required(),
      fun: Yup.boolean().required()
    })
  })
}).defined();

export default sideHustle;
