import React, { useContext } from 'react';
import {
  ActivityBadgeCard, ActivityStepIndicatorSummary, StepIndicatorDetails, Column, Artworks
} from '@paddl/storybook';
import { getActivityTitleByVariantId } from '@paddl/utils-js';
import { string } from 'prop-types';
import { ActivityStepIndicatorDetailsPanelContainer, ActivityStepIndicatorSummaryWrapper } from './NewActivityName.styles';
import { ActivityFormContext } from './ActivityFormContext';

const { details: Details, progress: Progress, skills: Skills } = Artworks;

export const SideContent = ({ category }) => {
  const {
    isDirty, setEdit, variantId, sectionCompletedCount
  } = useContext(ActivityFormContext);
  const activityTitle = getActivityTitleByVariantId(variantId);

  return (<>
    <ActivityBadgeCard
      type={activityTitle}
      isLocked
    />
    <ActivityStepIndicatorSummaryWrapper>
      <ActivityStepIndicatorSummary
        type={category || 'education'}
        stepNumber={sectionCompletedCount || 0}
      />
    </ActivityStepIndicatorSummaryWrapper>
    <ActivityStepIndicatorDetailsPanelContainer>
      <Column style={{ gap: '8px' }}>
        <StepIndicatorDetails
          icon={<Details />}
          title="Details"
          subtitle="Enter your Activity Details"
          isCompleted={isDirty.details || false}
          stepNumber={1}
          onClick={() => setEdit('details')}
        />
        <StepIndicatorDetails
          icon={<Progress />}
          title="Progress"
          subtitle="Enter your Start and Finish dates"
          isCompleted={isDirty.progress || false}
          stepNumber={2}
          onClick={() => setEdit('progress')}
        />
        <StepIndicatorDetails
          icon={<Skills />}
          title="Skills"
          subtitle="Tag the skills you have developed"
          isCompleted={isDirty.skills || false}
          stepNumber={3}
          onClick={() => setEdit('skills')}
        />
      </Column>
    </ActivityStepIndicatorDetailsPanelContainer>
          </>);
};

SideContent.propTypes = {
  category: string.isRequired
};
