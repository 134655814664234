const meetup = {
  name: '',
  organiser: {
    name: ''
  },
  meetupLocation: {
    originalText: '',
    shortText: ''
  },
  commitmentHours: '',
  description: '',
  typeId: null,
  attendeesId: null,
  isVirtual: false,
  isHost: false
};

export default meetup;
