import {
  Artworks,
  Button,
  InnerContentWrapper,
  MainContentWrapper,
  PageColumn,
  SkillsRead,
  badgesMetadata
} from '@paddl/storybook';
import {
  getActivityIsProgressDisabledByVariantId,
  getActivityProgressTypeByVariantId,
  getActivityTitleByVariantId,
  getCategoryNameByVariantId
} from '@paddl/utils-js';
import {
  Metadata,
  withDataFetching,
  withDataSubmission
} from '@paddl/utils-react';
import { func, shape, bool } from 'prop-types';
import React, {
  useEffect,
  useState
} from 'react';
import { compose } from 'recompose';
import {
  FormHeader,
  ShareModal
} from '../../components';
import { EditWrapper } from '../CreateActivity/NewActivityName/NewActivityName.styles';
import {
  ActivityWrapper,
  SubmitButtonWrapper
} from './Activity.styles';
import { detailsSectionFactory } from './DetailsSection/detailsSectionFactory';
import { EditableSectionWrapper } from './EditableSectionWrapper';
import DefaultMedia from './MediaSection/read/DefaultMedia';
import PaddlGames from './MediaSection/read/PaddlGames';
import { ProgressEdit, ProgressRead, progressFormSchema } from './ProgressSection';
import { getProgressStartLabel } from './ProgressSection/progressUtils';
import { SkillsEdit, skillsFormSchema } from './SkillsSection';

const {
  details: Details,
  progress: Progress,
  skills: Skills
} = Artworks;

export const ViewOwnActivityComp = ({
  activity,
  activity: {
    id: activityId,
    variantId,
    errorMessage
  },
  updateActivity: {
    submit,
    response: postRequestResponse
  },
  deleteActivityMedia: {
    fetch: fetchDeleteMedia
  },
  isShareModalOpen,
  setIsShareModalOpen
}) => {
  const [edit, setEdit] = useState(null);
  const [data, setData] = useState(activity);
  const [isDirty, setIsDirty] = useState(false);

  const activityTitle = variantId && getActivityTitleByVariantId(variantId);
  const activityName = activityTitle && badgesMetadata[activityTitle]?.name;
  const categoryName = getCategoryNameByVariantId(variantId || 1);
  const contenfulPageTitle = data?.contentfulFields?.title || '';
  const pageTitle = `${activityName || ''}${contenfulPageTitle && ' - '}${contenfulPageTitle}`;

  const renderCancelButton = () =>
    <Button
      onClick={() => setEdit(null)}
      variant="contained"
      color="white"
    >
      Cancel
    </Button>;

  const renderContinueButton = (updatedData, isDisabled = true) =>
    <Button
      onClick={() => {
        setData(updatedData);
        setEdit(null);
        setIsDirty(true);
      }}
      variant="contained"
      disabled={isDisabled}
    >
      Continue
    </Button>;

  const renderSubmitButton = () =>
    <SubmitButtonWrapper>
      <Button
        onClick={() => {
          submit(`${process.env.API_PREFIX_V2}/activities/${activityId}`, data);
        }}
        variant="contained"
        size="large"
      >
        Update Activity
      </Button>
    </SubmitButtonWrapper>;

  const renderSections = () => {
    const { DetailsRead, DetailsEdit, detailsFormSchema } = detailsSectionFactory(variantId);
    const progressType = getActivityProgressTypeByVariantId(variantId);
    const isProgressDisabled = getActivityIsProgressDisabledByVariantId(variantId);
    const progressStartDateLabel = getProgressStartLabel(variantId, progressType);

    return (
      <>
        <section>
          {edit === 'details'
            ? <EditWrapper>
                <FormHeader
                  data-test-id="edit-form-header"
                  icon={<Details />}
                  headline="Details"
                  subTitle={`Add your ${activityName} details...`}
                />
                <DetailsEdit
                  data-test-id="edit-details"
                  data={data}
                  cancelButton={renderCancelButton}
                  continueButton={renderContinueButton}
                  formSchema={detailsFormSchema}
                />
              </EditWrapper>
            : <EditableSectionWrapper canEdit onEditClick={() => setEdit('details')}>
              <DetailsRead
                data={data}
              />
              </EditableSectionWrapper>}
        </section>
        <section>
          {edit === 'progress'
            ? <EditWrapper>
                <FormHeader
                  icon={<Progress />}
                  headline="Progress"
                  subTitle="Enter your Start &amp; Finish dates for this Activity..."
                />
                <ProgressEdit
                  data={data}
                  startDateLabel={progressStartDateLabel}
                  cancelButton={renderCancelButton}
                  continueButton={renderContinueButton}
                  formSchema={progressFormSchema}
                  progressType={progressType}
                  isProgressDisabled={isProgressDisabled}
                />
              </EditWrapper>
            : <EditableSectionWrapper canEdit onEditClick={() => setEdit('progress')}>
            <ProgressRead
              data={data}
              progressType={progressType}
            /></EditableSectionWrapper>}
        </section>
        <section>
          {edit === 'skills'
            ? <EditWrapper>
                <FormHeader
                  icon={<Skills />}
                  headline="Relevant Skills"
                  subTitle="Add the skills that you developed during this Activity..."
                />
                <SkillsEdit
                  data={{ ...data }}
                  cancelButton={renderCancelButton}
                  continueButton={renderContinueButton}
                  formSchema={skillsFormSchema}
                />
              </EditWrapper>
            : <EditableSectionWrapper canEdit onEditClick={() => setEdit('skills')}><SkillsRead
                data={{ ...data }}
            /></EditableSectionWrapper>}
        </section>
      </>
    );
  };

  // variantId '4' => paddlGames
  const renderMediaSection = () => {
    return variantId.toString() === '4'
      ? <PaddlGames
          data={{ ...data }}
          activityName={activityName}
          isViewingOwnActivity
      />
      : <DefaultMedia
          data={{ ...data }}
          activityName={activityName}
          categoryName={categoryName}
          isViewingOwnActivity
      />;
  };

  useEffect(() => {
    if (isDirty && postRequestResponse?.events && postRequestResponse.events[0] === 'UPDATED') {
      // TODO: display snackbar with SUCCESS message before page reload
      window.location.reload();
    }

    if (isDirty && postRequestResponse?.errorMessage) {
      // TODO: display snackbar with ERROR message before page reload
      window.location.reload();
    }
  }, [setData, data, activityId, errorMessage, isDirty, fetchDeleteMedia, postRequestResponse]);

  return <>
  <ShareModal
    isShareModalOpen={isShareModalOpen}
    setIsShareModalOpen={setIsShareModalOpen}
    activityTitle={activityTitle}
    activityData={data}
  />
  <Metadata title={`${pageTitle} | Paddl`} />
  <MainContentWrapper>
  <PageColumn>
  {renderMediaSection()}
  <InnerContentWrapper size="small">
    <ActivityWrapper>
      {renderSections()}
      {(isDirty && !edit) ? renderSubmitButton() : null}
    </ActivityWrapper>
  </InnerContentWrapper>
  </PageColumn>
  </MainContentWrapper>
         </>;
};

ViewOwnActivityComp.propTypes = {
  activity: shape({}).isRequired,
  deleteActivityMedia: shape({
    fetch: func.isrequired
  }).isRequired,
  updateActivity: shape({
    submit: func.isrequired,
    response: shape({}).isRequired
  }).isRequired,
  isShareModalOpen: bool.isRequired,
  setIsShareModalOpen: func.isRequired
};

export const ViewOwnActivity = compose(
  withDataSubmission({ propNameWrapper: 'updateActivity' }),
  withDataFetching({ propNameWrapper: 'deleteActivityMedia' })
)(ViewOwnActivityComp);
