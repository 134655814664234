import React, { useState, useEffect } from 'react';
import {
  number,
  shape,
  string,
  func,
  oneOfType
} from 'prop-types';
import Grid from '@mui/material/Grid';
import {
  TextField,
  Dropdown
} from '@paddl/storybook';
import {
  prizeCategoryOptionList,
  jurisdictionOptionList,
  initGoogleLocationApi
} from '@paddl/utils-js';
import { updateValueByPathLookup } from '../../utils';

export const Prize = ({
  cancelButton,
  continueButton,
  formSchema,
  data,
  data: {
    details: {
      name,
      issuer = {},
      description,
      categoryId,
      jurisdictionId
    }
  }
}) => {
  const [newData, setNewData] = useState(data);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  const descriptionLength = newData.details.description?.length;

  const handleChange = (stringPath) => {
    const updatedData = updateValueByPathLookup(newData, stringPath);

    if (!isDirty) setIsDirty(true);
    if (JSON.stringify(newData) !== JSON.stringify(updatedData)) setNewData(updatedData);
  };

  useEffect(() => {
    if (isDirty && formSchema.isValidSync(newData)) setIsDisabled(false);
    if (isDirty && !formSchema.isValidSync(newData)) setIsDisabled(true);
    if (typeof window.google === 'undefined') initGoogleLocationApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData, formSchema, isDirty]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Prize Title"
            placeholder="e.g. State Prize"
            onChange={(item) => handleChange([{ path: 'details.name', value: item }])}
            defaultValue={name}
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Issuer / Provider"
            data-test-id="issuer"
            placeholder="e.g. State Government"
            onChange={(item) => handleChange([{
              path: 'details.issuer',
              value: {
                name: item,
                id: null,
                profileId: null,
                icon: null
              }
            }])}
            defaultValue={issuer.name}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={categoryId}
            data-test-id="category-id"
            label="Category"
            onChange={(item) => handleChange([{ path: 'details.categoryId', value: item }])}
            items={prizeCategoryOptionList}
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            defaultValue={jurisdictionId}
            data-test-id="jurisdiction-id"
            label="Jurisdiction"
            onChange={(item) => handleChange([
              { path: 'details.jurisdictionId', value: item }
            ])}
            items={jurisdictionOptionList}
            isRequired
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description (Optional)"
            data-test-id="description"
            placeholder="This prize is awarded to someone who..."
            maxLength={1500}
            onChange={(item) => handleChange([
              { path: 'details.description', value: item }
            ])}
            defaultValue={description}
            multiline
            helperText={`Max 1500${descriptionLength > 0 ? `/${descriptionLength}` : ''} Characters`}
          />
        </Grid>
      </Grid>
      <div className="footer">
        {cancelButton ? cancelButton() : <div />}
        {continueButton(newData, isDisabled)}
      </div>
    </>
  );
};

Prize.propTypes = {
  cancelButton: func.isRequired,
  continueButton: func.isRequired,
  formSchema: shape({}).isRequired,
  data: shape({
    details: shape({
      name: string.isRequired,
      issuer: shape({
        id: number,
        name: string
      }).isRequired,
      categoryId: oneOfType([number, string]),
      jurisdictionId: oneOfType([number, string]),
      description: string
    }).isRequired
  }).isRequired
};

export default Prize;
