import React, { useContext } from 'react';
import { Artworks, badgesMetadata } from '@paddl/storybook';
import {
  getActivityTitleByVariantId
} from '@paddl/utils-js';
import { EditWrapper } from './NewActivityName.styles';
import { FormHeader } from '../../../components';
import { detailsSectionFactory } from '../../Activity/DetailsSection/detailsSectionFactory';
import { EmptySection } from './EmptySection';
import { ContinueButton } from './ContinueButton';
import { ActivityFormContext } from './ActivityFormContext';
import { CancelButton } from './CancelButton';

const { details: Details } = Artworks;

export const DetailsSection = () => {
  const {
    isDirty, edit, variantId, setEdit, data
  } = useContext(ActivityFormContext);
  const detailsClassName = edit === 'details' ? 'editable' : 'viewable';
  const {
    DetailsEdit,
    detailsFormSchema
  } = detailsSectionFactory(variantId);
  const activityTitle = getActivityTitleByVariantId(variantId);
  const activityName = badgesMetadata[activityTitle]?.name;
  const renderContinueButton = (updatedData, isDisabled) => (<ContinueButton updatedData={updatedData} isDisabled={isDisabled} type="details" />);
  const renderCancelButton = () => <CancelButton />;

  return (
        <section
          className={
            isDirty.details
              ? detailsClassName
              : `${detailsClassName} empty-section-wrapper`
          }
        >
          {edit === 'details' ? (
            <EditWrapper>
              <FormHeader
                icon={<Details />}
                headline="Details"
                subTitle={`Add your ${activityName} details...`}
              />
              <DetailsEdit
                data={{ ...data }}
                continueButton={renderContinueButton}
                cancelButton={renderCancelButton}
                formSchema={detailsFormSchema}
              />
            </EditWrapper>
          ) : (
            <EmptySection
              icon={<Details />}
              headline="Details"
              subTitle={`Add your ${activityName} details...`}
              setEdit={() => setEdit('details')}
              isDirty={!!isDirty.details}
            />
          )}
        </section>
  );
};
