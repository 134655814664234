import styled from '@emotion/styled';
import { Colors, Fonts } from '@paddl/storybook';

const { new_grey } = Colors;

const contentWidth = '680px';

export const ContentWrapper = styled.div`
  max-width: ${contentWidth};
  padding: 16px 16px 40px;
  margin: ${({ breakpoint }) => (breakpoint === 'xs' ? '16px auto' : '0 auto 16px auto')};

  .title {
    ${Fonts.H2}
    text-align: center;
  }

  .subtitle {
    ${Fonts.body}
    color: ${new_grey};
    text-align: center;
    margin-bottom: 36px;
  }
`;

export const CardVoteWrapper = styled.div`
  margin-top: 48px;
`;
